import { useQuery } from '@tanstack/react-query';
import api from '../api/api';

const useGetProjectDetails = (id) => {
    return useQuery({
        queryKey: ['project', id],
        queryFn: async () => {
            const response = await api.get(`/projects/${id}`);
            return response.data;
        },
    });
};

export default useGetProjectDetails;