import React from 'react';

const SubmitButton = ({ btnName, icon }) => {
  return (
    <div className="my-5">
      <button
        type="submit"
        className="group relative inline-flex items-center  overflow-hidden rounded bg-indigo-600 px-8 py-3 text-white focus:outline-none focus:ring active:bg-indigo-500"
      >
        <span className="absolute -start-full transition-all group-hover:start-4">
          {icon}
        </span>

        <span className="text-sm font-medium transition-all group-hover:ms-4">
          {btnName}
        </span>
      </button>
    </div>
  );
};

export default SubmitButton;
