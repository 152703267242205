import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api/api';
import HoursCard from '../../components/card/hoursCard/HoursCard';
import BarChartDs from '../../components/charts/BarChartDs';
import LineChartDs from '../../components/charts/LineChartDs';
import Header from '../../components/header/header/Header';
import { useAuth } from '../../context/AuthContext';
import Loading from '../../widgets/loading/Loading';

const DirectSupports = () => {
  const [summaryList, setSummaryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        setLoading(true);

        const response = await api.get('/sales/ds/summary');
        setSummaryList(response.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          setError(error);
          setErrorMessage(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else {
          console.log(`Error: ${error.message}`);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSummary();
  }, []);

  let increaseDecreaseInfo = {};

  if (!loading) {
    if (summaryList.length >= 2) {
      var lastItem = summaryList[summaryList.length - 1];
      var secondLastItem = summaryList[summaryList.length - 2];
      Object.keys(lastItem).forEach((key) => {
        if (
          key !== '_id' &&
          key !== 'weekEnding' &&
          key !== 'totalHours' &&
          key !== '__v'
        ) {
          var increaseDecrease = lastItem[key] - secondLastItem[key];
          var percentageChange = (
            (increaseDecrease / secondLastItem[key]) *
            100
          ).toFixed(2);
          increaseDecreaseInfo[key] = {
            change: increaseDecrease > 0 ? 'increased' : 'decreased',
            hours: lastItem[key],
            percentage: Math.abs(percentageChange),
          };
        }
      });
    }
  }
  const userData = useAuth();
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Header />
          {userData.userData.position !== 'tl' && (
            <div className="grid grid-cols-1 gap-4 mt-5 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
              {Object.entries(increaseDecreaseInfo).map(
                ([community, { change, hours, percentage }]) => (
                  <HoursCard
                    key={community}
                    comm={community}
                    change={change}
                    hours={hours}
                    percentage={percentage}
                  />
                )
              )}
            </div>
          )}
          {userData.userData.position === 'tl' && (
            <div className="shadow-md rounded-lg mt-5">
              <p className="p-4 text-slate-600 font-bold">
                Last {summaryList.length} weeks
              </p>
              {summaryList && summaryList.length > 0 && (
                <BarChartDs summaryList={summaryList} />
              )}
            </div>
          )}

          {userData.userData.position !== 'tl' && (
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mt-6 ">
              <div className="shadow-md rounded-lg">
                <p className="p-4 text-slate-600 font-bold">
                  Last {summaryList.length} weeks
                </p>
                {summaryList && summaryList.length > 0 && (
                  <BarChartDs summaryList={summaryList} />
                )}
              </div>

              <div className="shadow-md rounded-lg">
                <p className="p-4 text-slate-600 font-bold">
                  Last {summaryList.length} weeks
                </p>
                {summaryList && summaryList.length > 0 && (
                  <LineChartDs summaryList={summaryList} />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DirectSupports;
