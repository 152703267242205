import React from 'react';
import { CiTimer } from 'react-icons/ci';

const HoursCard = ({ comm, change, hours, percentage }) => {
  return (
    <article className="rounded-lg border border-gray-100 bg-white p-6 shadow-md">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm text-gray-500">{comm}</p>
          <div className="flex gap-1">
            <p className="text-2xl font-medium text-gray-900">{hours}</p>
            <p className="self-end mb-[1px]">Hours</p>
          </div>
        </div>

        <span className="rounded-full bg-blue-100 p-3 text-blue-600">
          <CiTimer />
        </span>
      </div>
      {change === 'increased' ? (
        <div className="mt-1 flex gap-1 text-green-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
            />
          </svg>

          <p className="flex gap-2 text-xs">
            <span className="font-medium">{percentage}%</span>

            <span className="text-gray-500"> Since last week </span>
          </p>
        </div>
      ) : (
        <div className="mt-1 flex gap-1 text-red-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6"
            />
          </svg>

          <p className="flex gap-2 text-xs">
            <span className="font-medium"> {percentage}% </span>

            <span className="text-gray-500"> Since last week </span>
          </p>
        </div>
      )}
    </article>
  );
};

export default HoursCard;
