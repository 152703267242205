import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api/api';
import ActionCard from '../../components/card/ActionCard/ActionCard';
import MemberEventCard from '../../components/card/memberEventCard/MemberEventCard';
import MemberInfoCard from '../../components/card/memberInfoCard/MemberInfoCard';
import PieChartFunding from '../../components/charts/PieChartFunding';
import ActionForm from '../../components/forms/actionForm/ActionForm';
import UpdateDsMember from '../../components/forms/updateDsMember/UpdateDsMember';
import Modal from '../../components/modal/Modal';
import ModalWithContent from '../../components/modal/ModalWithContent';
import Projection from '../../components/projection/Projection';
import { useAuth } from '../../context/AuthContext';
import InformationAlert from '../../widgets/alert/InformationAlert';
import Loading from '../../widgets/loading/Loading';
import CardSection from './CardSection';

const MemberPage = ({ members, loading }) => {
  const queryClient = useQueryClient();

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [deleteIsLoading, deleteSetIsLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isActionUpdateModalOpen, setIsActionUpdateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const navigate = useNavigate();
  const { userData } = useAuth();

  const [singleMember, setSingleMember] = useState({});

  useEffect(() => {
    const foundMember = members?.find((m) => m._id === id);
    setSingleMember(foundMember);
    setIsLoading(false);
  }, [members, id]);

  if (!isLoading && singleMember?.details) {
    var coreBudgets = singleMember?.details?.planBudgets?.filter(
      (budget) => budget.group.toLowerCase() === 'core budget'
    );

    var budgetArray = coreBudgets?.map((budget) => ({
      planStartDate: singleMember?.details.ndisPlanStartDate,
      planEndDate: singleMember?.details.ndisPlanEndDate,
      ...budget,
    }));
  }

  const handleDelete = async (id) => {
    try {
      deleteSetIsLoading(true);
      await api.delete(`/membersWithDetails`, {
        data: { id: id },
      });

      queryClient.invalidateQueries(['membersWithDetails']);

      navigate('/');
      toast.success('User deleted successfully');
    } catch (error) {
      console.log(error.message);
      toast.error('Failed to delete user');
    } finally {
      deleteSetIsLoading(false);
    }
  };

  return (
    <>
      {isLoading || loading ? (
        <Loading />
      ) : (
        <>
          {userData && userData?.position === 'tl' && open && (
            <InformationAlert open={setOpen} />
          )}
          <div className="flex justify-between items-center mt-12 md:mt-0">
            <h1 className="text-3xl text-slate-500 font-bold">
              {singleMember?.name}
            </h1>
            <div>
              <button
                onClick={() => {
                  setIsOpen(true);
                }}
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-1 rounded-md"
              >
                Delete
              </button>
            </div>
          </div>
          <div>
            {singleMember?.details?.planBudgets?.length > 0 ? (
              <>
                <CardSection
                  planStart={singleMember?.details?.ndisPlanStartDate}
                  planEnd={singleMember?.details?.ndisPlanEndDate}
                  fundingTypes={singleMember?.details?.planBudgets}
                />

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 mt-10">
                  <MemberInfoCard
                    memberInfo={singleMember}
                    planStart={singleMember?.details?.ndisPlanStartDate}
                    planEnd={singleMember?.details?.ndisPlanEndDate}
                    setIsUpdateModalOpen={setIsUpdateModalOpen}
                  />
                  <PieChartFunding
                    loading={isLoading || loading}
                    planBudgets={singleMember?.details}
                  />
                </div>

                <ActionCard
                  setIsActionUpdateModalOpen={setIsActionUpdateModalOpen}
                  actionArray={singleMember?.actionsArray}
                  actionTaken={singleMember?.actionTaken}
                />

                <MemberEventCard singleMember={singleMember} />

                <div className="grid grid-cols-1 gap-4 mt-10">
                  {budgetArray ? (
                    budgetArray?.map((budget) => (
                      <Projection
                        key={budget.categoryId}
                        planStart={budget.planStartDate}
                        planEnd={budget.planEndDate}
                        category={budget.category}
                        budget={budget.budget}
                        remaining={budget.remaining}
                      />
                    ))
                  ) : (
                    <p>No Data in CareView</p>
                  )}
                </div>
              </>
            ) : (
              <div className="mt-5 ">
                <MemberInfoCard
                  memberInfo={singleMember}
                  planStart={singleMember?.details?.ndisPlanStartDate}
                  planEnd={singleMember?.details?.ndisPlanEndDate}
                  setIsUpdateModalOpen={setIsUpdateModalOpen}
                />
                <ActionCard
                  setIsActionUpdateModalOpen={setIsActionUpdateModalOpen}
                  actionArray={singleMember?.actionsArray}
                  actionTaken={singleMember?.actionTaken}
                />
                <MemberEventCard singleMember={singleMember} />
                <p className="text-lg mt-5 text-center">
                  Currently this member has no careview active data.
                </p>
              </div>
            )}
          </div>
        </>
      )}
      {/* Modal */}
      <Modal
        btnName={deleteIsLoading ? 'Deleting...' : 'Delete'}
        title="Delete the user"
        message="Are you sure you want to delete this user? This action cannot be undone."
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        exitBtnAction={() => setIsOpen(false)}
        btnAction={() => handleDelete(id)}
      />
      <ModalWithContent
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
        content={
          <>
            <UpdateDsMember
              members={members}
              setMembers={singleMember?.details}
              id={id}
              singleMember={singleMember}
              setIsUpdateModalOpen={setIsUpdateModalOpen}
              setSingleMember={setSingleMember}
            />
          </>
        }
      />
      <ModalWithContent
        isOpen={isActionUpdateModalOpen}
        setIsOpen={setIsActionUpdateModalOpen}
        content={
          <>
            <ActionForm
              members={members}
              setMembers={singleMember?.details}
              id={id}
              singleMember={singleMember}
              setIsActionUpdateModalOpen={setIsActionUpdateModalOpen}
              setSingleMember={setSingleMember}
            />
          </>
        }
      />
    </>
  );
};

export default MemberPage;
