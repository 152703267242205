import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { toast } from 'react-toastify';
import api from '../../api/api';
import useFetchDSSettings from '../../hooks/useFetchDSSettings';
import Loading from '../../widgets/loading/Loading';
import BreadCrumb from '../breadCrumb/BreadCrumb';
import UpdateCategoryForm from '../forms/updateCategoryForm/UpdateCategoryForm';
import Modal from '../modal/Modal';
import ModalWithContent from '../modal/ModalWithContent';
import ReusableTable from './ReusableTable';

const AllCategoryTable = ({ setSelectedComponent }) => {
  const queryClient = useQueryClient();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [category, setCategory] = useState('');

  const { data: settings, isLoading: loading } = useFetchDSSettings();

  function navigation() {
    setSelectedComponent(null);
  }

  const deleteCategory = async () => {
    if (actionLoading) return;
    try {
      setActionLoading(true);
      const response = await api.delete(
        `/directSupportSettings/${category?._id}`
      );

      if (response.status === 200) {
        toast.success('Event Item deleted successfully!');
        queryClient.invalidateQueries(['settings']);
      } else {
        throw new Error('Failed to delete item');
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error('Failed to delete item. Please try again.');
    } finally {
      setActionLoading(false);
      setOpenDeleteModal(false);
      setCategory('');
    }
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('categoryName', {
      header: 'Category Name',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('createdBy', {
      header: 'Created By',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: (props) => {
        const rowData = props.row.original;
        return (
          <div className="flex gap-3">
            <button
              onClick={() => {
                setCategory(rowData);
                setOpenEditModal(true);
              }}
              className="text-blue-600 hover:text-blue-800"
            >
              <FaEdit size={18} />
            </button>
            <button
              onClick={() => {
                setCategory(rowData);
                setOpenDeleteModal(true);
              }}
              className="text-red-600 hover:text-red-800"
            >
              <FaTrash size={18} />
            </button>
          </div>
        );
      },
    }),
  ];

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <BreadCrumb
            currentPage="All Categories"
            prevPage="Direct Support Settings"
            icon={<FiSettings />}
            navigation={navigation}
          />
          <div className="mt-5">
            {settings?.[0]?.categories?.length > 0 ? (
              <ReusableTable
                tableData={settings?.[0]?.categories}
                columns={columns}
              />
            ) : (
              <h1 className="uppercase tracking-widest text-gray-500 mt-16 text-center">
                No category has been created yet
              </h1>
            )}
          </div>
        </div>
      )}
      <Modal
        btnName={actionLoading ? 'Deleting...' : 'Delete Category'}
        title="Delete Category?"
        message="Are you sure you want to delete this category? This action cannot be undone."
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        exitBtnAction={() => setOpenDeleteModal(false)}
        btnAction={() => deleteCategory()}
      />
      <ModalWithContent
        isOpen={openEditModal}
        setIsOpen={setOpenEditModal}
        content={
          <UpdateCategoryForm
            setOpenEditModal={setOpenEditModal}
            category={category}
          />
        }
      />
    </div>
  );
};

export default React.memo(AllCategoryTable);
