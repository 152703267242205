import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { IoMdAdd } from 'react-icons/io';
import { IoEyeOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import ButtonGroup from '../../components/buttonGroup/ButtonGroup';
import ReusableTable from '../../components/table/ReusableTable';
import { formatDate } from '../../utils/DateFormation';
import Loading from '../../widgets/loading/Loading';

const Projects = () => {
  const { data: projects = [], isLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: async () => {
      const response = await api.get('/projects');
      return response.data;
    },
  });

  const navigate = useNavigate();

  const columnHelper = createColumnHelper();

  const columns = React.useMemo(() => {
    return [
      columnHelper.accessor('projectName', {
        header: 'Project Name',
      }),
      columnHelper.accessor('startDate', {
        header: 'Start Date',
        cell: (info) => (
          <span className="text-nowrap">{formatDate(info.getValue())}</span>
        ),
      }),
      columnHelper.accessor('endDate', {
        header: 'End Date',
        cell: (info) => (
          <span className="text-nowrap">{formatDate(info.getValue())}</span>
        ),
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => (
          <span className="px-3 py-1 text-nowrap text-sm rounded-full bg-blue-100 text-blue-800">
            {info.getValue()}
          </span>
        ),
      }),
      columnHelper.accessor('stakeHolders', {
        header: 'Stakeholders',
        cell: (info) => (
          <div className="flex flex-wrap gap-1">
            {info.getValue().map((stakeholder) => (
              <span
                key={stakeholder._id}
                className="px-2 py-1 rounded-lg text-sm bg-gray-200 text-gray-800"
              >
                {stakeholder.name}
              </span>
            ))}
          </div>
        ),
      }),
      columnHelper.accessor('completionPercentage', {
        header: 'Completion',
        cell: (info) => (
          <div className="flex gap-1 items-center">
            <span className="text-xs text-gray-500">{info.getValue()}%</span>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-green-600 h-2.5 rounded-full"
                style={{ width: `${info.getValue()}%` }}
              ></div>
            </div>
          </div>
        ),
        minSize: 100,
      }),
      columnHelper.accessor('visits', {
        cell: (info) => (
          <div className="flex items-center space-x-3.5">
            <IoEyeOutline
              role="button"
              onClick={() => navigate(`/project/${info.row.original._id}`)}
            />
          </div>
        ),
        header: 'View',
      }),
    ];
  }, [columnHelper]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="mb-5">
        <ButtonGroup
          navigationPath={'/project/add'}
          title="Add Project"
          icon={<IoMdAdd />}
        />
      </div>
      <div>
        {projects.length > 0 ? (
          <ReusableTable tableData={projects || []} columns={columns} />
        ) : (
          <h1 className="uppercase tracking-widest text-gray-500 mt-16 text-center">
            No projects available
          </h1>
        )}
      </div>
    </div>
  );
};

export default Projects;
