import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useCallback, useMemo, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { IoEyeOutline } from 'react-icons/io5';
import { LiaGreaterThanSolid, LiaLessThanSolid } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';
import TableSearch from './TableSearch';

const DsMember = ({ memberWithCalculatedBudget }) => {
  const columnHelper = createColumnHelper();
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState('');
  const [communityFilter, setCommunityFilter] = useState('All Communities');
  const [showAll, setShowAll] = useState(false);

  // Memoize the filtered data to prevent unnecessary recalculations
  const filteredData = useMemo(() => {
    let filtered = memberWithCalculatedBudget;

    // Apply budget status filter if showAll is false
    if (!showAll) {
      filtered = filtered.filter((item) => item.budgetStatus !== 'No Data');
    }

    // Apply community filter
    if (communityFilter !== 'All Communities') {
      filtered = filtered.filter((item) => item.community === communityFilter);
    }

    return filtered;
  }, [communityFilter, memberWithCalculatedBudget, showAll]);

  const columns = [
    columnHelper.accessor('', {
      id: 'S.No',
      cell: (info) => <span>{info.row.index + 1}</span>,
      header: 'S.No',
    }),

    columnHelper.accessor('name', {
      cell: (info) => (
        <span className="min-w-40 inline-block truncate">
          {info.getValue()}
        </span>
      ),
      header: 'Participant Name',
      size: 180,
    }),
    columnHelper.accessor('ndis', {
      cell: (info) => <span>{info.getValue()}</span>,
      header: 'NDIS',
    }),
    columnHelper.accessor('community', {
      cell: (info) => {
        const serviceType = info.row.original.serviceType;
        const community = info.getValue();
        const showSA =
          serviceType === 'support_coordination' || serviceType === 'both';

        return (
          <span
            className={`rounded-md bg-opacity-10 py-1 px-3 text-sm font-medium w-fit ${
              community === 'A'
                ? 'bg-green-400 text-green-600'
                : community === 'B'
                ? 'bg-cyan-400 text-cyan-600'
                : community === 'C'
                ? 'bg-violet-400 text-violet-600'
                : 'bg-pink-400 text-pink-600'
            }`}
          >
            {showSA ? `${community ? `${community}, SC` : 'SC'}` : community}
          </span>
        );
      },
      header: 'Community',
    }),
    columnHelper.accessor('budgetStatus', {
      cell: (info) => (
        <span
          className={`whitespace-nowrap ${
            info.getValue() === 'Overspending'
              ? 'text-red-500'
              : info.getValue() === 'Underspending'
              ? 'text-blue-500'
              : 'text-slate-600'
          }`}
        >
          {info.getValue() === 'Overspending'
            ? 'Over Utilised'
            : info.getValue() === 'Underspending'
            ? 'Under Utilised'
            : 'No Data'}
        </span>
      ),
      header: 'Status',
    }),
    columnHelper.accessor('actionTaken', {
      cell: (info) => (
        <span
          className={`${
            info.getValue() ? 'text-green-500' : ''
          } ml-2 lg:ml-0 min-w-32 inline-block`}
        >
          {info.getValue() ? 'Taken' : 'Not Taken'}
        </span>
      ),
      header: 'Action Status',
      size: 120,
    }),

    columnHelper.accessor('visits', {
      cell: (info) => (
        <div className="flex items-center space-x-3.5">
          <IoEyeOutline
            role="button"
            onClick={() =>
              navigate(`/direct-support/participant/${info.row.original._id}`)
            }
          />
        </div>
      ),
      header: 'View',
    }),
  ];

  // Optimize table instance with memoized data
  const table = useReactTable({
    data: filteredData,
    columns,
    state: {
      globalFilter,
    },
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  // Optimize the community filter change handler
  const handleCommunityChange = useCallback(
    (value) => {
      setCommunityFilter(value);
      table.setPageIndex(0); // Reset to first page when filter changes
    },
    [table]
  );

  return (
    <div className="p-2 max-w-[100%] mx-auto text-slate-600 fill-gray-400 border rounded-lg shadow-md">
      <div className="w-full sm:flex gap-1 space-y-2 sm:space-y-0 mb-2">
        <div className="w-full sm:w-64 flex items-center gap-1 lg:mr-10">
          <CiSearch />
          <TableSearch
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 bg-transparent border-none w-full duration-300"
            placeholder="Search all columns..."
          />
        </div>
        <div className="w-full flex items-center gap-1">
          <select
            className="rounded outline-none border-none p-1 w-40 mr-5 lg:mr-10"
            value={communityFilter}
            onChange={(e) => handleCommunityChange(e.target.value)}
          >
            <option value="All Communities">All Communities</option>
            <option value="A">Community A</option>
            <option value="B">Community B</option>
            <option value="C">Community C</option>
            <option value="D">Community D</option>
          </select>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="showAll"
              checked={showAll}
              onChange={(e) => setShowAll(e.target.checked)}
              className="rounded"
            />
            <label htmlFor="showAll">Show All</label>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="border rounded-lg w-full text-left text-slate-600 min-w-[650px]">
          <thead className="bg-slate-200">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className="capitalize px-3.5 py-2">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row, i) => (
                <tr
                  key={row.id}
                  className={i % 2 === 0 ? 'bg-white' : 'bg-slate-50'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="px-1 lg:px-3.5 py-1 lg:py-2">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr className="text-center h-32">
                <td colSpan={12}>No Record Found!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex items-start sm:items-center justify-end mt-2 gap-2">
        <div className="flex items-center gap-2">
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="p-1 text-indigo-700 border border-gray-100 px-2 rounded-md"
          >
            <LiaLessThanSolid size={20} />
          </button>
          <span className="flex text-slate-600 items-center gap-1">
            <p>Page</p>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="p-1 text-indigo-700 border border-gray-100 px-2 rounded-md"
          >
            <LiaGreaterThanSolid size={20} />
          </button>
        </div>

        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
          <span className="hidden sm:flex items-center gap-1 text-slate-600">
            Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="border p-1 rounded w-12 h-8 border-slate-100"
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            className="border p-1 rounded w-24 sm:w-32 h-8 border-slate-100 text-slate-600"
          >
            {[10, 20, 30, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default DsMember;
