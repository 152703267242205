import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { FaProjectDiagram } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import api from '../../../api/api';
import useGetProjectDetails from '../../../hooks/useGetProjectDetails';
import useGetAllUser from '../../../hooks/useGetUser';
import Loading from '../../../widgets/loading/Loading';
import BreadCrumb from '../../breadCrumb/BreadCrumb';
import ModalWithContent from '../../modal/ModalWithContent';
import SelectUserModalContent from '../../selectUserModalComponent/selectUserModalContent';
import Input from '../formElements/inputs/Input';
import LoadingButton from '../formElements/loadingButton/LoadingButton';
import SelectInput from '../formElements/slectInput/SelectInput';
import SubmitButton from '../formElements/submitButton/SubmitButton';

const EditProjectForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: projectDetails, isLoading } = useGetProjectDetails(id);

  const { data, isLoading: usersLoading } = useGetAllUser();
  const [formData, setFormData] = useState({
    projectName: '',
    description: '',
    status: '',
    startDate: '',
    endDate: '',
    departments: [],
    approvalStatus: '',
    budget: '',
  });

  useEffect(() => {
    if (projectDetails) {
      setFormData({
        projectName: projectDetails.projectName,
        description: projectDetails.description,
        status: projectDetails.status,
        startDate: projectDetails.startDate.split('T')[0],
        endDate: projectDetails.endDate.split('T')[0],
        departments: projectDetails.departments,
        approvalStatus: projectDetails.approvalStatus,
        budget: projectDetails.budget,
      });
      setSelectedUsersList(projectDetails.stakeHolders);
    }
  }, [projectDetails]);

  const statusOptions = [
    { value: 'Not Started', name: 'Not Started' },
    { value: 'In Planning', name: 'In Planning' },
    { value: 'In Progress', name: 'In Progress' },
    { value: 'Pushed Back', name: 'Pushed Back' },
    { value: 'Completed', name: 'Completed' },
  ];

  const departments = [
    'Direct Supports',
    'Support Coordination',
    'Plan Management',
    'Finance',
    'HR',
    'Company',
  ];

  const approvalOptions = [
    { value: true, label: 'Approved' },
    { value: false, label: 'Not Approved' },
  ];

  const navigation = () => {
    navigate(`/project/${id}`);
  };

  const [isAddingProject, setIsAddingProject] = useState(false);

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  const [selectedUsersList, setSelectedUsersList] = useState([]);

  const queryClient = useQueryClient();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.projectName ||
      !formData.description ||
      !formData.status ||
      !formData.startDate ||
      !formData.endDate ||
      !formData.departments.length ||
      !formData.approvalStatus ||
      !selectedUsersList.length ||
      !formData.budget
    ) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsAddingProject(true);
    try {
      await api.put(`/projects/${id}`, {
        ...formData,
        stakeHolders: selectedUsersList,
        updateType: 'editProject',
      });
      await queryClient.invalidateQueries({ queryKey: ['projects'] });
      toast.success('Project added successfully');
      navigate(`/project/${id}`);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Something went wrong');
    } finally {
      setIsAddingProject(false);
    }
  };

  // Add this configuration for Quill
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
  ];

  // Modify the departments array to match react-select format
  const departmentOptions = departments.map((dept) => ({
    value: dept,
    label: dept,
  }));

  if (isLoading || usersLoading) return <Loading />;

  return (
    <div>
      <BreadCrumb
        currentPage="Edit Project"
        prevPage="Project Details"
        icon={<FaProjectDiagram />}
        navigation={navigation}
      />

      <form
        onSubmit={handleSubmit}
        className="w-full shadow-md rounded-md mt-2"
      >
        <div className="w-full h-14 bg-slate-200">
          <h3 className="text-xl p-4 font-bold text-slate-600 pb-4">
            Projects
          </h3>
        </div>
        <div className="md:py-4 pl-5 md:pl-10 xl:pl-14  pb-4">
          <Input
            value={formData.projectName}
            change={(value) => setFormData({ ...formData, projectName: value })}
            type="text"
            id="projectName"
            placeholder="Project Name"
          />

          {/* Add the Quill editor here */}
          <div className="my-4 w-[95%]">
            <label className="block font-medium text-gray-700 mb-2">
              Project Description <span className="text-red-500">*</span>
            </label>
            <div className="h-48">
              <ReactQuill
                theme="snow"
                value={formData.description}
                onChange={(content) =>
                  setFormData((prev) => ({ ...prev, description: content }))
                }
                modules={modules}
                formats={formats}
                className="h-36"
              />
            </div>
          </div>

          <SelectInput
            options={statusOptions}
            id="status"
            placeholder="Status"
            value={formData.status}
            change={(value) => setFormData({ ...formData, status: value })}
          />

          <Input
            value={formData.startDate}
            change={(value) => setFormData({ ...formData, startDate: value })}
            type="date"
            id="startDate"
            placeholder="Start Date"
          />

          <Input
            value={formData.endDate}
            change={(value) => setFormData({ ...formData, endDate: value })}
            type="date"
            id="endDate"
            placeholder="End Date"
          />

          <Input
            value={formData.budget}
            change={(value) => setFormData({ ...formData, budget: value })}
            type="number"
            id="budget"
            placeholder="Project Budget"
          />

          <div className="my-4 w-[95%]">
            <label className="block font-medium text-gray-700 mb-2">
              Departments <span className="text-red-500">*</span>
            </label>
            <Select
              isMulti
              name="departments"
              options={departmentOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              value={formData.departments.map((dept) => ({
                value: dept,
                label: dept,
              }))}
              onChange={(selectedOptions) => {
                setFormData({
                  ...formData,
                  departments: selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : [],
                });
              }}
              placeholder="Select departments..."
            />
          </div>

          <div className="my-4">
            <label className="block font-medium text-gray-700 mb-2">
              Approval Status <span className="text-red-500">*</span>
            </label>
            <div className="flex flex-wrap items-center gap-6">
              {approvalOptions.map((item) => (
                <div key={item.label} className="flex items-center">
                  <input
                    type="radio"
                    id={item.label}
                    name="approvalStatus"
                    value={item.value}
                    checked={formData.approvalStatus === item.value}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        approvalStatus: e.target.value === 'true',
                      }))
                    }
                    required
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500"
                  />
                  <label
                    htmlFor={item.label}
                    className="ml-2 text-sm text-gray-700"
                  >
                    {item.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="my-4">
            <label className="block font-medium text-gray-700 mb-2 ">
              Stake Holders
            </label>
            <div className="mb-5">
              <button
                type="button"
                className="px-5 py-1 bg-blue-600 rounded-lg text-white hover:bg-blue-700 transition-colors"
                onClick={() => setIsAddUserModalOpen(true)}
              >
                {selectedUsersList.length
                  ? 'Edit Stake Holders'
                  : 'Add Stake Holders'}
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {selectedUsersList.map((user) => (
                <div
                  key={user._id}
                  className="flex items-center gap-3 bg-white p-3 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-shadow"
                >
                  <div className="flex-shrink-0 w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
                    <span className="text-blue-600 font-medium text-lg">
                      {user.name.charAt(0).toUpperCase()}
                    </span>
                  </div>
                  <div className="flex-grow min-w-0">
                    <h4 className="font-medium text-gray-900 truncate">
                      {user.name}
                    </h4>
                    <p className="text-sm text-gray-500 truncate">
                      {user.email}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            {isAddingProject ? (
              <LoadingButton />
            ) : (
              <SubmitButton
                btnName="Save Project"
                icon={<AiOutlineArrowRight />}
              />
            )}
          </div>
        </div>
      </form>
      <ModalWithContent
        isOpen={isAddUserModalOpen}
        setIsOpen={setIsAddUserModalOpen}
        content={
          <SelectUserModalContent
            setSelectedUsersList={setSelectedUsersList}
            selectedUsersList={selectedUsersList}
            onClose={() => setIsAddUserModalOpen(false)}
            users={data}
          />
        }
      />
    </div>
  );
};

export default EditProjectForm;
