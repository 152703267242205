import { useQuery } from '@tanstack/react-query';
import api from '../api/api';

const useFetchDSSettings = () => {
    return useQuery({
        queryKey: ['settings'],
        queryFn: async () => {
            const response = await api.get('/directSupportSettings');
            return Array.isArray(response.data) ? response.data : [];
        },
    });
};

export default useFetchDSSettings;
