import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api/api';
import LoadingButton from '../formElements/loadingButton/LoadingButton';
import SubmitButton from '../formElements/submitButton/SubmitButton';
import TextArea from '../formElements/textArea/TextArea';

const EditDetailsForm = ({
  setIsEditDetailsModalOpen,
  setEditType,
  editType,
  eventDetails,
}) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const [eventPlan, setEventPlan] = useState(eventDetails.eventPlan);
  const [eventDescription, setEventDescription] = useState(
    eventDetails.eventDescription
  );

  const mutation = useMutation({
    mutationFn: async (newItem) => {
      try {
        return await api.put(`/events/${id}`, newItem);
      } catch (err) {
        throw err;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['events', id] });
      toast.success('Details updated successfully');
      setIsEditDetailsModalOpen(false);
      setEditType('');
    },
    onError: (error) => {
      console.error('Error in mutation onError:', error);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      type: 'editDetails',
      eventPlan: eventPlan,
      eventDescription: eventDescription,
    };
    setLoading(true);
    try {
      await mutation.mutateAsync(data);
    } catch (error) {
      const errorMsg =
        error.response?.data?.message || 'An unexpected error occurred.';
      console.error('Caught in handleSubmit:', error);
      toast.error(errorMsg, {
        position: 'bottom-left',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    } finally {
      setLoading(false);
      setEventDescription('');
      setEventPlan('');
    }
  };

  return (
    <>
      <div className="mt-2">
        <div className="w-full">
          <form action="" onSubmit={handleSubmit}>
            <div className="w-full h-14 bg-slate-200">
              <h3 className="text-xl p-4 font-bold text-slate-600">
                Action Details
              </h3>
            </div>
            <div className="flex flex-col items-center">
              {editType === 'eventDescription' ? (
                <TextArea
                  id="eventDescription"
                  value={eventDescription}
                  change={(value) => setEventDescription(value)}
                  placeholder="Event Description"
                />
              ) : (
                <TextArea
                  id="eventPlan"
                  value={eventPlan}
                  change={(value) => setEventPlan(value)}
                  placeholder="Event Plan"
                />
              )}

              {loading ? (
                <LoadingButton />
              ) : (
                <SubmitButton btnName="Submit" icon={<AiOutlineArrowRight />} />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditDetailsForm;
