import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import CategorySettingCard from '../../../components/card/CategorySettingCard/CategorySettingCard';
import AddCategoryForm from '../../../components/forms/addCategoryForm/addCategoryForm';
import AllCategoryTable from '../../../components/table/AllCategoryTable';
import DirectSupportMenu from '../DirectSupportMenu';

const DirectSupportSettings = () => {
  const [selectedComponent, setSelectedComponent] = useState(null);

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'addCategory':
        return (
          <motion.div
            key="addCategory"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
          >
            <AddCategoryForm setSelectedComponent={setSelectedComponent} />
          </motion.div>
        );
      case 'showCategory':
        return (
          <motion.div
            key="showCategory"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
          >
            <AllCategoryTable setSelectedComponent={setSelectedComponent} />
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <DirectSupportMenu />
      <div>
        <AnimatePresence mode="wait">
          {selectedComponent === null && (
            <motion.div
              key="categoryCard"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <CategorySettingCard
                setSelectedComponent={setSelectedComponent}
              />
            </motion.div>
          )}
          {renderComponent()}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default React.memo(DirectSupportSettings);
