import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import api from '../api/api';

const useFetchInsight = () => {

    return useQuery({
        queryKey: ['insights'],
        queryFn: async () => {
            try {
                const response = await api.get('/insights');
                return Array.isArray(response.data) ? response.data : [];
            } catch (error) {
                console.error('Error fetching insights:', error);
                toast.error('Error fetching insights');
                return [];
            }
        },
    });
};

export default useFetchInsight;
