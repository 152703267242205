import { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { FaProjectDiagram } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../api/api';
import Loading from '../../../widgets/loading/Loading';
import BreadCrumb from '../../breadCrumb/BreadCrumb';
import ModalWithContent from '../../modal/ModalWithContent';
import SelectUserModalContent from '../../selectUserModalComponent/selectUserModalContent';
import Input from '../formElements/inputs/Input';
import LoadingButton from '../formElements/loadingButton/LoadingButton';
import TextArea from '../formElements/textArea/TextArea';
import useGetAllUser from './../../../hooks/useGetUser';
import SubmitButton from './../formElements/submitButton/SubmitButton';

const AddStepForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const { data, isLoading } = useGetAllUser();

  const [formData, setFormData] = useState({
    description: '',
    startDate: '',
    deadline: '',
    creationTime: new Date().toISOString(),
  });

  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const [isAddingStep, setIsAddingStep] = useState(false);

  const handleSubmit = async (e) => {
    setIsAddingStep(true);
    e.preventDefault();
    if (selectedUsersList.length === 0) {
      toast.error('No involved people selected');
      setIsAddingStep(false);
      return;
    }
    if (formData.description.trim() === '') {
      toast.error('No description provided');
      setIsAddingStep(false);
      return;
    }
    try {
      await api.put(`/projects/${id}`, {
        ...formData,
        selectedUsersList,
        updateType: 'addStep',
      });
      toast.success('Step added successfully');
      navigate(`/project/${id}`);
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Error submitting form');
      console.error('Error submitting form:', error);
    } finally {
      setIsAddingStep(false);
    }
  };

  if (isLoading) return <Loading />;

  return (
    <div>
      <BreadCrumb
        currentPage="Add Step"
        prevPage="Project Details"
        icon={<FaProjectDiagram />}
        navigation={() => navigate(`/project/${id}`)}
      />
      <form onSubmit={handleSubmit} className="">
        <TextArea
          value={formData.description}
          change={(value) => setFormData({ ...formData, description: value })}
          id="description"
          placeholder="Description"
          resize={true}
        />

        <Input
          value={formData.startDate}
          change={(value) => setFormData({ ...formData, startDate: value })}
          type="date"
          id="startDate"
          placeholder="Start Date"
        />

        <Input
          value={formData.deadline}
          change={(value) => setFormData({ ...formData, deadline: value })}
          type="date"
          id="deadline"
          placeholder="Deadline"
        />

        <div className="my-4">
          <label className="block font-medium text-gray-700 mb-2 ">
            People Involved
          </label>
          <div className="mb-5">
            <button
              type="button"
              className="px-5 py-1 bg-blue-600 rounded-lg text-white hover:bg-blue-700 transition-colors"
              onClick={() => setIsAddUserModalOpen(true)}
            >
              {selectedUsersList.length
                ? 'Edit People Involved'
                : 'Add People Involved'}
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {selectedUsersList.map((user) => (
              <div
                key={user._id}
                className="flex items-center gap-3 bg-white p-3 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-shadow"
              >
                <div className="flex-shrink-0 w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
                  <span className="text-blue-600 font-medium text-lg">
                    {user.name.charAt(0).toUpperCase()}
                  </span>
                </div>
                <div className="flex-grow min-w-0">
                  <h4 className="font-medium text-gray-900 truncate">
                    {user.name}
                  </h4>
                  <p className="text-sm text-gray-500 truncate">{user.email}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          {isAddingStep ? (
            <LoadingButton />
          ) : (
            <SubmitButton btnName="Add Step" icon={<AiOutlineArrowRight />} />
          )}
        </div>
        <ModalWithContent
          isOpen={isAddUserModalOpen}
          setIsOpen={setIsAddUserModalOpen}
          content={
            <SelectUserModalContent
              setSelectedUsersList={setSelectedUsersList}
              selectedUsersList={selectedUsersList}
              onClose={() => setIsAddUserModalOpen(false)}
              users={data}
            />
          }
        />
      </form>
    </div>
  );
};

export default AddStepForm;
