import React from 'react';

const ErrorBoundaryMessage = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="text-center p-8 rounded-lg shadow-lg ">
        <p className="text-gray-600 mb-6">Error loading the component.</p>
        <button
          onClick={() => window.location.reload()}
          className="px-5 py-1 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          Reload
        </button>
      </div>
    </div>
  );
};

export default React.memo(ErrorBoundaryMessage);
