import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import App from "./App";
import ErrorBoundaryMessage from "./components/errorBoundaryMessage/ErrorBoundaryMessage";
import { AuthProvider } from "./context/AuthContext";
import { DataProvider } from "./context/DataContext";
import "./index.css";
import ErrorBoundary from "./utils/ErrorBoundary";

// Initialize the QueryClient
const queryClient = new QueryClient();

// Set up global error handling
window.onerror = function (message, source, lineno, colno, error) {
  console.error("Global Error:", message, source, lineno, colno, error);
};

window.addEventListener("error", (event) => {
  console.error("Caught by window.addEventListener:", event.error || event.message);
});

window.addEventListener("unhandledrejection", (event) => {
  console.error("Unhandled Promise Rejection:", event.reason);
});


// Create the root element
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <DataProvider>
        <BrowserRouter future={{
          v7_startTransition: true,
        }}>
          <QueryClientProvider client={queryClient}>
            <ErrorBoundary fallback={<ErrorBoundaryMessage />}>
              <App />
            </ErrorBoundary>
          </QueryClientProvider>
        </BrowserRouter>
      </DataProvider>
    </AuthProvider>
  </React.StrictMode >
);
