import { LineChart } from '@mui/x-charts/LineChart';
import React from 'react';
import { useAuth } from '../../context/AuthContext';

const LineChartDs = ({ summaryList }) => {
  const dateList = summaryList.length
    ? summaryList.map((summary) => {
        const date = new Date(summary.weekEnding);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-indexed
        const year = String(date.getFullYear()).substr(-2);
        return `${day}/${month}/${year}`;
      })
    : [];
  const communityA = summaryList.length
    ? summaryList.map((summary) => Number(summary.communityA))
    : [];
  const communityB = summaryList.length
    ? summaryList.map((summary) => Number(summary.communityB))
    : [];
  const communityC = summaryList.length
    ? summaryList.map((summary) => Number(summary.communityC))
    : [];
  const communityD = summaryList.length
    ? summaryList.map((summary) => Number(summary.communityD))
    : [];

  const a = communityA;
  const b = communityB;
  const c = communityC;
  const d = communityD;
  const xLabels = dateList;

  const userData = useAuth();

  return (
    <>
      <LineChart
        height={350}
        series={[
          { data: a, label: 'Community A' },
          { data: b, label: 'Community B' },
          { data: c, label: 'Community C' },
          { data: d, label: 'Community D' },
        ]}
        xAxis={[{ scaleType: 'point', data: xLabels }]}
      />
    </>
  );
};

export default LineChartDs;
