import React from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

const COLORS = ['#45B39D', '#6577F3', '#8FD0EF'];

const PieChartFunding = ({ planBudgets }) => {
  const memberPlan = planBudgets?.planBudgets || [];
  const budgetArr = budgetArray(memberPlan).map((item) =>
    isNaN(item) ? 0 : item
  );

  // Prepare data for Recharts
  const data = [
    { name: 'Consumables', value: budgetArr[0] },
    { name: 'Social & Community Participation', value: budgetArr[1] },
    { name: 'Assistance With Daily Life', value: budgetArr[2] },
  ];

  return (
    <div className="rounded-sm border border-stroke py-3 px-7.5 shadow-md dark:border-strokedark dark:bg-boxdark">
      <div className="mb-5 justify-between gap-4 sm:flex ml-4">
        <div>
          <h5 className="text-xl font-semibold text-slate-600">
            Core Funds Analytics
          </h5>
        </div>
      </div>

      <div className="mb-2">
        <ResponsiveContainer width="100%" height={310}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={100}
              dataKey="value"
              label
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

// Helper function to calculate the budget array
const budgetArray = (obj = []) => {
  let totalBudget = 0;

  for (let item of obj) {
    if (['1', '3', '4'].includes(item.categoryId) && item.budget) {
      totalBudget += item.budget;
    }
  }

  if (totalBudget === 0) {
    return [0, 0, 0];
  }

  let budgetArray = [0, 0, 0];
  for (let item of obj) {
    if (item.budget) {
      if (item.categoryId === '3') {
        budgetArray[0] =
          parseFloat(((item.budget / totalBudget) * 100).toFixed(1)) || 0;
      } else if (item.categoryId === '4') {
        budgetArray[1] =
          parseFloat(((item.budget / totalBudget) * 100).toFixed(1)) || 0;
      } else if (item.categoryId === '1') {
        budgetArray[2] =
          parseFloat(((item.budget / totalBudget) * 100).toFixed(1)) || 0;
      }
    }
  }

  return budgetArray;
};

export default PieChartFunding;
