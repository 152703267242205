import React from 'react';

const ActionCard = ({
  actionArray,
  actionTaken,
  setIsActionUpdateModalOpen,
}) => {
  return (
    <div className="rounded-sm border border-stroke bg-white p-3 px-7.5 shadow-md dark:border-strokedark dark:bg-boxdark mt-10">
      <div className="mb-5 sm:flex justify-between items-center">
        <h5 className="text-xl text-slate-600 font-semibold">Action List</h5>
        <button
          onClick={() => setIsActionUpdateModalOpen(true)}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 rounded-md"
        >
          Take Action
        </button>
      </div>

      {actionTaken ? (
        actionArray?.map((action) => (
          <div
            key={action._id}
            className="border rounded-lg p-4 mb-4 shadow-sm bg-gray-50 hover:bg-gray-100 transition-colors"
          >
            <div className="flex justify-between items-center mb-2">
              <h6 className="text-lg font-semibold text-gray-800">
                {action.userName}
              </h6>
              <span className="text-sm text-gray-500">
                {new Date(action.time).toLocaleString()}
              </span>
            </div>
            <p className="text-gray-600">
              Action:{' '}
              <span className="font-medium">{action.actionDetails}</span>
            </p>
          </div>
        ))
      ) : (
        <p className="border rounded-lg p-4 mb-4 shadow-sm bg-gray-100 hover:bg-gray-100 transition-colors text-center">
          No action has been taken yet
        </p>
      )}
    </div>
  );
};

export default React.memo(ActionCard);
