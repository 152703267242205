import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api/api';
import Input from '../formElements/inputs/Input';
import LoadingButton from '../formElements/loadingButton/LoadingButton';
import SelectInput from '../formElements/slectInput/SelectInput';
import SubmitButton from '../formElements/submitButton/SubmitButton';
import TextArea from '../formElements/textArea/TextArea';

const UpdateEventTaskForm = ({
  setIsUpdateEventTaskModalOpen,
  selectedTask,
  setSelectedTask,
}) => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [taskDetails, setTaskDetails] = useState(selectedTask?.taskDetails);
  const [taskStatus, setTaskStatus] = useState(selectedTask?.taskStatus);
  const [dueDate, setDueDate] = useState(
    selectedTask?.dueDate
      ? new Date(selectedTask.dueDate).toISOString().split('T')[0]
      : ''
  );

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newTask) => {
      try {
        return await api.put(`/events/${id}`, newTask);
      } catch (err) {
        throw err;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['events', id] });
      toast.success('Task added Successfully');
      setIsUpdateEventTaskModalOpen(false);
      setTaskDetails('');
      setTaskStatus('');
      setDueDate('');
      setSelectedTask('');
    },
    onError: (error) => {
      console.error('Error in mutation onError:', error);
    },
  });

  // Status options for dropdown
  const statusOptions = [
    { value: 'Not Started', name: 'Not Started' },
    { value: 'Inprogress', name: 'Inprogress' },
    { value: 'Completed', name: 'Completed' },
    { value: 'Not Required', name: 'Not Required' },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newTask = {
      type: 'updateEventTask',
      taskDetails,
      taskStatus,
      dueDate: dueDate ? new Date(dueDate).toISOString() : '',
      taskId: selectedTask?._id,
    };

    setLoading(true);
    try {
      await mutation.mutateAsync(newTask);
    } catch (error) {
      const errorMsg =
        error.response?.data?.message || 'An unexpected error occurred.';
      console.error('Caught in handleSubmit:', error);
      toast.error(errorMsg, {
        position: 'bottom-left',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <div>
          <form action="" onSubmit={handleSubmit}>
            <div className="w-full h-14 bg-slate-200">
              <h3 className="text-xl p-4 font-bold text-slate-600">
                Update Task
              </h3>
            </div>
            <div className="flex flex-col items-center">
              <TextArea
                id="taskDetails"
                value={taskDetails}
                change={setTaskDetails}
                placeholder="Task Details"
              />
              <SelectInput
                id="taskStatus"
                value={taskStatus}
                change={setTaskStatus}
                placeholder="Task Status"
                options={statusOptions}
              />
              <Input
                id="dueDate"
                value={dueDate}
                change={setDueDate}
                placeholder="Due Date"
                type="date"
              />
              {loading ? (
                <LoadingButton />
              ) : (
                <SubmitButton
                  btnName={'Update Task'}
                  icon={<AiOutlineArrowRight />}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default React.memo(UpdateEventTaskForm);
