import React, { useState } from 'react';
import { formatDate } from '../../utils/DateFormation';
import AddInsightOutcomeForm from '../forms/addInsightOutcomeForm/AddInsightOutcomeForm';
import ModalWithContent from '../modal/ModalWithContent';

const InsightDetails = ({ insightDetails = {} }) => {
  const [isAddInsightOutcomeModalOpen, setIsAddInsightOutcomeModalOpen] =
    useState(false);
  const handleActionClick = () => {
    setIsAddInsightOutcomeModalOpen(true);
  };

  return (
    <div className="p-6 min-h-screen">
      {/* Action Button */}
      <div className="flex justify-end mb-5">
        {(!insightDetails.dateResolved || !insightDetails.outcome) && (
          <button
            onClick={handleActionClick}
            className="px-6 py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-200"
          >
            Take Action
          </button>
        )}
      </div>

      {/* Details Card */}
      <div className="shadow-lg rounded-lg p-6 space-y-6">
        {/* Name and Description */}
        <div>
          <h2 className="text-xl font-bold text-gray-800 mb-2">
            Insight Details
          </h2>
          <p className="text-gray-600 text-sm">{insightDetails.description}</p>
        </div>

        {/* Insight Attributes */}
        <div className="space-y-4">
          <DetailItem label="Category" value={insightDetails.category} />
          <DetailItem label="Linked To" value={insightDetails.linkedTo} />
          <DetailItem
            label={`${insightDetails.linkedTo} Name`}
            value={insightDetails.name}
          />
          <DetailItem
            label="Impact Level"
            value={insightDetails.impactLevel}
            badgeColor={
              insightDetails.impactLevel === 'high'
                ? 'bg-red-100 text-red-800'
                : 'bg-blue-100 text-blue-800'
            }
          />

          <DetailItem
            label="Assigned To"
            value={
              Array.isArray(insightDetails?.assignedTo) ? (
                <div className="flex flex-wrap gap-1">
                  {insightDetails?.assignedTo.map((assignee, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 text-sm bg-gray-200 text-gray-700 rounded-md"
                    >
                      {assignee.name}
                    </span>
                  ))}
                </div>
              ) : (
                <span className="px-2 py-1 text-sm bg-gray-100 text-gray-700 rounded-md">
                  {insightDetails?.assignedTo}
                </span>
              )
            }
          />

          <DetailItem label="Created By" value={insightDetails.createdBy} />
          <DetailItem
            label="Created By"
            value={formatDate(insightDetails.dateIdentified)}
          />
          <DetailItem
            label="Due Date"
            value={
              insightDetails.dueDate
                ? formatDate(insightDetails.dueDate)
                : 'N/A'
            }
          />
        </div>

        {/* Outcome and Resolved Date */}
        {insightDetails.outcome && (
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">
              Outcome
            </h3>
            <p className="text-gray-600 text-sm">{insightDetails.outcome}</p>
          </div>
        )}
        {insightDetails.dateResolved && (
          <DetailItem
            label="Resolved On"
            value={formatDate(insightDetails.dateResolved)}
          />
        )}
        {insightDetails.resolvedBy && (
          <DetailItem label="Resolved By" value={insightDetails.resolvedBy} />
        )}
      </div>
      <ModalWithContent
        content={
          <AddInsightOutcomeForm
            setIsAddInsightOutcomeModalOpen={setIsAddInsightOutcomeModalOpen}
          />
        }
        isOpen={isAddInsightOutcomeModalOpen}
        setIsOpen={setIsAddInsightOutcomeModalOpen}
      />
    </div>
  );
};

// Helper component for displaying details
const DetailItem = ({ label, value, badgeColor }) => (
  <div className="flex items-start">
    <span className="text-gray-500 font-medium w-40">{label}:</span>
    {badgeColor ? (
      <span className={`px-3 py-1 rounded-full text-sm ${badgeColor}`}>
        {value}
      </span>
    ) : (
      <span className="text-gray-800">{value}</span>
    )}
  </div>
);

export default React.memo(InsightDetails);
