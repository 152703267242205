import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../api/api';
import Input from '../formElements/inputs/Input';
import TextArea from '../formElements/textArea/TextArea';

const AddInsightOutcomeForm = ({ setIsAddInsightOutcomeModalOpen }) => {
  const { id } = useParams();
  const userData = JSON.parse(localStorage.getItem('user_data'));
  const userName = userData?.user?.name;
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    dateResolved: '',
    outcome: '',
    resolvedBy: userName,
  });

  const queryClient = useQueryClient();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.put(`/insights/${id}`, formData);
      queryClient.invalidateQueries({ queryKey: ['insights', id] });
      toast.success('Outcome added successfully');
    } catch (error) {
      toast.error('Adding outcome failed');
      console.error('Error updating insight:', error);
    } finally {
      setLoading(false);
      setIsAddInsightOutcomeModalOpen(false);
    }
  };

  return (
    <div>
      <div className="w-full h-14 bg-slate-200">
        <h3 className="text-xl p-4 font-bold text-slate-600">
          Add Insight Outcome
        </h3>
      </div>

      <form onSubmit={handleSubmit} className="p-4">
        <div className="max-h-[60vh] overflow-y-auto">
          <div className="mb-4">
            <Input
              value={formData.dateResolved}
              change={(value) =>
                setFormData((prev) => ({ ...prev, dateResolved: value }))
              }
              type="date"
              id="dateResolved"
              placeholder="Date Resolved"
            />
          </div>

          <TextArea
            resize={true}
            value={formData.outcome}
            change={(value) =>
              setFormData((prev) => ({ ...prev, outcome: value }))
            }
            id="outcome"
            placeholder="Outcome"
          />
        </div>

        <button
          type="submit"
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default React.memo(AddInsightOutcomeForm);
