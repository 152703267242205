import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import PropTypes from 'prop-types';
import React from 'react';
import { BsCalendarPlus } from 'react-icons/bs';
import { IoEyeOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api/api';
import ButtonGroup from '../../components/buttonGroup/ButtonGroup';
import ReusableTable from '../../components/table/ReusableTable';
import useEventRevenue from '../../hooks/useEventRevenue';
import { formatDate } from '../../utils/DateFormation';
import Loading from '../../widgets/loading/Loading';

const StatusFilter = ({ value, onChange }) => {
  const options = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'both', label: 'Both' },
  ];

  return (
    <div className="mt-4 flex items-center gap-4">
      {options.map((option) => (
        <label key={option.value} className="flex items-center gap-2">
          <input
            type="radio"
            name="status"
            value={option.value}
            checked={value === option.value}
            onChange={(e) => onChange(e.target.value)}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

StatusFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const BellowTargetCell = React.memo(({ event }) => {
  const { belowTargetPercentage } = useEventRevenue(event);

  const getColorClasses = () => {
    if (belowTargetPercentage === undefined || belowTargetPercentage === null) {
      return 'bg-yellow-400 text-yellow-600'; // Yellow for no data
    }
    if (belowTargetPercentage === 0) {
      return 'bg-green-400 text-green-600'; // Green for 0%
    }
    return 'bg-red-400 text-red-600'; // Red for any other percentage
  };

  return (
    <span
      className={`inline-block whitespace-nowrap rounded-md bg-opacity-10 py-1 px-3 text-sm font-medium ${getColorClasses()}`}
    >
      {belowTargetPercentage !== undefined && belowTargetPercentage !== null
        ? `${belowTargetPercentage}%`
        : '-'}
    </span>
  );
});

const Events = () => {
  const [statusFilter, setStatusFilter] = React.useState('active');
  const navigate = useNavigate();

  const {
    data: events = [],
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ['events'],
    queryFn: async () => {
      try {
        const response = await api.get('/events');
        return Array.isArray(response.data) ? response.data : [];
      } catch (error) {
        console.error('Error fetching events:', error);
        toast.error('Error fetching events');
        return [];
      }
    },
  });

  const filteredEvents = React.useMemo(() => {
    if (!Array.isArray(events)) return [];
    if (statusFilter === 'both') return events;
    return events.filter((event) =>
      statusFilter === 'active' ? event.status : !event.status
    );
  }, [events, statusFilter]);

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper();
    return [
      columnHelper.accessor('', {
        id: 'S.No',
        cell: (info) => <span>{info.row.index + 1}</span>,
        header: 'S.No',
      }),
      columnHelper.accessor('eventName', {
        header: 'Event Name',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('budget', {
        header: 'Budget',
        cell: (info) => '$' + info.getValue(),
      }),
      columnHelper.accessor('startDate', {
        header: 'Start Date',
        cell: (info) => formatDate(info.getValue()),
      }),
      columnHelper.accessor('noOfRequiredParticipants', {
        header: 'No. of Participants',
        cell: (info) => {
          return info.row.original?.participantsIds?.length;
        },
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => (info.getValue() ? 'Active' : 'Inactive'),
      }),
      columnHelper.accessor('type', {
        header: 'Below Target',
        cell: (info) => <BellowTargetCell event={info.row.original} />,
      }),
      columnHelper.accessor('visits', {
        cell: (info) => (
          <div className="flex items-center space-x-3.5">
            <IoEyeOutline
              role="button"
              onClick={() => navigate(`/event/${info.row.original._id}`)}
            />
          </div>
        ),
        header: 'View',
      }),
    ];
  }, [navigate]);

  return (
    <>
      <div className="mb-5">
        <ButtonGroup
          navigationPath={'/event/add'}
          title="Add Event"
          icon={<BsCalendarPlus />}
        />
        <StatusFilter value={statusFilter} onChange={setStatusFilter} />
      </div>
      <div>
        {error ? (
          <div className="rounded-md mt-10 bg-red-100 text-red-700 text-center px-4 py-3">
            Error: {error.message}
          </div>
        ) : loading ? (
          <Loading />
        ) : (
          <div>
            {filteredEvents?.length > 0 ? (
              <ReusableTable tableData={filteredEvents} columns={columns} />
            ) : (
              <div className="rounded-md mt-10 bg-gray-200 text-center px-4 py-3">
                No Events Available
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(Events);
