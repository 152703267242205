import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../api/api';
import Input from '../formElements/inputs/Input';
import LoadingButton from '../formElements/loadingButton/LoadingButton';
import SubmitButton from './../formElements/submitButton/SubmitButton';

const MarkProjectCompleteForm = ({
  stepId,
  setOpenMarkAsCompleteModal,
  setStepId,
}) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState({
    timeInvested: '',
  });

  const [isAddingStep, setIsAddingStep] = useState(false);

  const handleSubmit = async (e) => {
    setIsAddingStep(true);
    e.preventDefault();

    try {
      await api.put(`/projects/${id}`, {
        ...formData,
        stepId,
        updateType: 'completeStep',
      });
      toast.success('Project marked as complete');
      queryClient.invalidateQueries(['project', id]);
    } catch (error) {
      toast.error('Error submitting form');
      console.error('Error submitting form:', error);
    } finally {
      setIsAddingStep(false);
      setOpenMarkAsCompleteModal(false);
      setStepId('');
    }
  };

  return (
    <div>
      <h2>Is the project completed?</h2>
      <form onSubmit={handleSubmit} className="">
        <Input
          value={formData.timeInvested}
          change={(value) => setFormData({ ...formData, timeInvested: value })}
          type="number"
          id="timeInvested"
          placeholder="Time Invested (hours)"
        />

        <div>
          {isAddingStep ? (
            <LoadingButton />
          ) : (
            <SubmitButton
              btnName="Mark Complete"
              icon={<AiOutlineArrowRight />}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default MarkProjectCompleteForm;
