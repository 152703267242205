import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api/api';
import PopUpError from '../../../widgets/error/PopUpError';
import Input from '../formElements/inputs/Input';
import LoadingButton from '../formElements/loadingButton/LoadingButton';
import SelectInput from '../formElements/slectInput/SelectInput';
import SubmitButton from '../formElements/submitButton/SubmitButton';

const UpdateDsMember = ({
  id,
  singleMember,
  setIsUpdateModalOpen,
  setSingleMember,
}) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const [community, setCommunity] = useState(singleMember?.community || '');
  const [name, setName] = useState(singleMember?.name || '');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateMemberData = { id, community, name };

    try {
      setLoading(true);
      const response = await api.put('/membersWithDetails', updateMemberData);

      if (response?.data) {
        setSingleMember((prevMember) => ({
          ...prevMember,
          community: response.data.community,
          name: response.data.name,
        }));

        await queryClient.invalidateQueries({
          queryKey: ['membersWithDetails'],
        });

        toast.success('User Updated Successfully', {
          position: 'bottom-left',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      }
    } catch (error) {
      toast.error(error.response);
      console.log(error);
      setError(true);
      if (error.response) {
        setErrorMessage(error.response);
      } else {
        setErrorMessage({ data: { message: 'An error occurred' } });
      }
    } finally {
      setLoading(false);
      setIsUpdateModalOpen(false);
    }
  };

  return (
    <>
      <div className="mt-2">
        {error && errorMessage.data && (
          <PopUpError message={errorMessage.data.message} />
        )}

        <div className="w-full">
          <form action="" onSubmit={handleSubmit}>
            <div className="w-full h-14 bg-slate-200">
              <h3 className="text-xl p-4 font-bold text-slate-600">
                Update User
              </h3>
            </div>
            <div className="flex flex-col items-center">
              <Input
                id="name"
                value={name}
                change={(value) => setName(value)}
                placeholder="Full Name"
                type="text"
              />
              <Input
                id="ndis"
                value={singleMember?.ndis || ''}
                placeholder="NDIS Number"
                type="text"
                disabled={true}
              />
              <SelectInput
                value={community}
                change={setCommunity}
                id="community"
                placeholder="Community"
                options={[
                  { value: 'A', name: 'A' },
                  { value: 'B', name: 'B' },
                  { value: 'C', name: 'C' },
                  { value: 'D', name: 'D' },
                ]}
              />
              {loading ? (
                <LoadingButton />
              ) : (
                <SubmitButton
                  btnName="Update Member"
                  icon={<AiOutlineArrowRight />}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateDsMember;
