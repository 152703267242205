import React from 'react';
import { GrUserAdd } from 'react-icons/gr';
import ButtonGroup from '../../components/buttonGroup/ButtonGroup';
import DsMember from '../../components/table/DsMember';
import useGetMemberWithBudget from '../../hooks/useGetMemberWithBudget';
import Loading from '../../widgets/loading/Loading';
import DirectSupportMenu from '../directSupports/DirectSupportMenu';

const MembersList = () => {
  const {
    memberWithCalculatedBudget,
    loading: isLoading,
    isError,
  } = useGetMemberWithBudget();

  if (isError) {
    return <p>Error loading members...</p>;
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <DirectSupportMenu />
          <div className="mb-2">
            <ButtonGroup
              navigationPath={'/direct-support/participant/add'}
              title="Add Participant"
              icon={<GrUserAdd />}
            />
          </div>

          {memberWithCalculatedBudget?.length ? (
            <DsMember memberWithCalculatedBudget={memberWithCalculatedBudget} />
          ) : (
            <p style={{ marginTop: '2rem' }}>
              There are no members in the system
            </p>
          )}
        </>
      )}
    </>
  );
};

export default MembersList;
