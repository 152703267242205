import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import ReusableTable from '../../../components/table/ReusableTable';
import useFetchInsight from '../../../hooks/useFetchInsight';
import { formatDate } from '../../../utils/DateFormation';
import Loading from '../../../widgets/loading/Loading';
import DirectSupportMenu from '../DirectSupportMenu';

const Insight = () => {
  const navigate = useNavigate();
  const { data: insights = [], isLoading: loading, error } = useFetchInsight();

  const columnHelper = createColumnHelper();

  const columns = React.useMemo(() => {
    return [
      columnHelper.accessor('category', {
        header: 'Category',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => {
          const dateResolved = info.row.original.dateResolved;
          return (
            <span
              className={`px-2 py-1 rounded-full text-nowrap ${
                dateResolved
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800'
              }`}
            >
              {dateResolved ? 'Resolved' : 'Not Resolved'}
            </span>
          );
        },
      }),
      columnHelper.accessor('assignedTo', {
        header: 'Assigned To',
        cell: (info) => {
          const assignees = info.getValue();
          return Array.isArray(assignees) ? (
            <div className="flex flex-wrap gap-1">
              {assignees.map((assignee, index) => (
                <span
                  key={index}
                  className="px-2 py-1 text-sm bg-gray-200 text-gray-700 rounded-md"
                >
                  {assignee.name}
                </span>
              ))}
            </div>
          ) : (
            <span className="px-2 py-1 text-sm bg-gray-100 text-gray-700 rounded-md">
              {assignees}
            </span>
          );
        },
      }),
      columnHelper.accessor('linkedTo', {
        header: 'Linked To',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('impactLevel', {
        header: 'Impact Level',
        cell: (info) => {
          const impact = info.getValue();
          const colorClasses = {
            low: 'bg-green-100 text-green-800',
            medium: 'bg-yellow-100 text-yellow-800',
            high: 'bg-red-100 text-red-800',
          };
          return (
            <span
              className={`px-2 capitalize py-1 rounded-full ${colorClasses[impact]}`}
            >
              {impact}
            </span>
          );
        },
      }),
      columnHelper.accessor('dueDate', {
        header: 'Due Date',
        cell: (info) => {
          const dueDate = info.getValue();
          if (!dueDate) return 'Pending';

          const isOverdue = new Date(dueDate) < new Date();
          return (
            <span
              className={`px-2.5 py-1 rounded-full text-nowrap ${
                isOverdue
                  ? 'bg-red-100 text-red-800'
                  : 'bg-green-100 text-green-800'
              }`}
            >
              {formatDate(dueDate)}
            </span>
          );
        },
      }),
      columnHelper.accessor('visits', {
        cell: (info) => (
          <div className="flex items-center space-x-3.5">
            <IoEyeOutline
              role="button"
              onClick={() =>
                navigate(`/direct-support/insight/${info.row.original._id}`)
              }
            />
          </div>
        ),
        header: 'View',
      }),
    ];
  }, [columnHelper]);

  return (
    <div>
      <DirectSupportMenu />

      {loading ? (
        <Loading />
      ) : error ? (
        <div className="text-center py-5">Error fetching insights</div>
      ) : (
        <div>
          <div className="flex justify-end mb-2">
            <Link
              to="/direct-support/insight/add"
              className="px-3 py-1 bg-blue-600 text-white rounded-md"
            >
              Add Insight
            </Link>
          </div>
          <div>
            {insights.length > 0 ? (
              <ReusableTable tableData={insights || []} columns={columns} />
            ) : (
              <h1 className="uppercase tracking-widest text-gray-500 mt-16 text-center">
                No insights available
              </h1>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Insight);
