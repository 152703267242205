import React from 'react';
import { useNavigate } from 'react-router-dom';

const ButtonGroup = ({ navigationPath, title, icon }) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(navigationPath);
  };
  return (
    <span className="inline-flex overflow-hidden rounded-md border bg-white shadow-sm">
      <button
        onClick={handleNavigation}
        className="inline-block border-e px-4 py-2 text-sm font-medium text-gray-700 hover:bg-indigo-600 hover:text-white focus:relative"
      >
        {title}
      </button>

      <button
        className="inline-block px-4 py-2 text-gray-700 hover:bg-gray-50 focus:relative"
        title="View Orders"
        disabled
      >
        {icon}
      </button>
    </span>
  );
};

export default ButtonGroup;
