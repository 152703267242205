import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api/api';
import Loading from '../../widgets/loading/Loading';
import ModalWithContent from '../modal/ModalWithContent';
import ReusableTable from '../table/ReusableTable';
import useGetMemberWithBudget from './../../hooks/useGetMemberWithBudget';

const Participants = ({ eventDetails, selectedParticipantsDetails }) => {
  const queryClient = useQueryClient();

  const { memberWithCalculatedBudget, loading, isError } =
    useGetMemberWithBudget();

  const { id } = useParams();
  const navigate = useNavigate();
  const [isAddParticipantModalOpen, setIsAddParticipantModalOpen] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [selectedParticipantsList, setSelectedParticipantsList] = useState([
    ...eventDetails?.participantsIds.map((p) =>
      typeof p === 'string' ? p : p._id
    ),
  ]);
  const [isSaving, setIsSaving] = useState(false);
  const [participantAttendance, setParticipantAttendance] = useState(
    eventDetails?.participantAttendance || {}
  );

  const filteredMembers = useMemo(
    () =>
      memberWithCalculatedBudget?.filter(
        (member) =>
          member.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          member.ndis.includes(searchQuery)
      ),
    [memberWithCalculatedBudget, searchQuery]
  );

  const handleParticipantSelect = useCallback(
    (member) => {
      if (!selectedParticipantsList.includes(member._id)) {
        setSelectedParticipantsList((prev) => [...prev, member._id]);
      }
    },
    [selectedParticipantsList]
  );

  const handleRemoveParticipant = (memberId) => {
    setSelectedParticipantsList(
      selectedParticipantsList.filter((id) => id !== memberId)
    );
  };

  const handleSaveParticipants = async () => {
    try {
      setIsSaving(true);
      const response = await api.put(`/events/${id}`, {
        type: 'addParticipantsIds',
        participantsIds: selectedParticipantsList,
      });

      if (response.status === 200) {
        toast.success('Participants saved successfully!');
        setIsAddParticipantModalOpen(false);
        queryClient.invalidateQueries(['events', id]);
      } else {
        throw new Error('Failed to save participants');
      }
    } catch (error) {
      console.error('Error saving participants:', error);
      toast.error(
        error.message || 'An error occurred while saving participants.'
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleAttendanceChange = async (participantId, value) => {
    setParticipantAttendance((prev) => ({
      ...prev,
      [participantId]: { attendance: value },
    }));

    if (value === 'Not Attended') {
      try {
        const response = await api.put(`/events/${id}`, {
          type: 'updateParticipantAttendance',
          participantId,
          attendance: value,
        });

        if (response.status === 200) {
          toast.success('Attendance updated successfully!');
          queryClient.invalidateQueries(['events', id]);
        }
      } catch (error) {
        setParticipantAttendance((prev) => {
          const updated = { ...prev };
          delete updated[participantId];
          return updated;
        });
        console.error('Error updating attendance:', error);
        toast.error('Failed to update attendance. Please try again.');
      }
    }
  };

  const handleHoursChange = async (participantId, hours) => {
    try {
      setParticipantAttendance((prev) => ({
        ...prev,
        [participantId]: {
          ...prev[participantId],
          attendance: 'Attended',
          attendedHours: hours,
        },
      }));

      const response = await api.put(`/events/${id}`, {
        type: 'updateParticipantAttendance',
        participantId,
        attendance: 'Attended',
        attendedHours: parseInt(hours),
      });

      if (response.status === 200) {
        toast.success('Attendance hours updated successfully!');
        queryClient.invalidateQueries(['events', id]);
      }
    } catch (error) {
      setParticipantAttendance((prev) => ({
        ...prev,
        [participantId]: {
          ...prev[participantId],
          attendedHours:
            eventDetails?.participantAttendance?.[participantId]
              ?.attendedHours || '',
        },
      }));
      console.error('Error updating attendance hours:', error);
      toast.error('Failed to update attendance hours. Please try again.');
    }
  };

  // Select member modal
  const modalContent = (
    <div className="space-y-6">
      {/* Search Input */}
      <div className="relative">
        <input
          type="text"
          placeholder="Search participants by name or NDIS number..."
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute right-3 top-3 h-5 w-5 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 4H16M8 20H16M4 12H20"
          />
        </svg>
      </div>

      {/* Members List */}
      <div className="max-h-60 overflow-y-auto border border-gray-200 rounded-lg shadow-sm">
        {filteredMembers?.map((member) => (
          <div
            key={member._id}
            onClick={() => handleParticipantSelect(member)}
            className="p-3 hover:bg-blue-50 cursor-pointer flex justify-between items-center border-b last:border-b-0"
          >
            <div>
              <span className="font-medium text-gray-800">{member.name}</span>
              <span className="text-sm text-gray-500 ml-2">
                ({member.ndis})
              </span>
            </div>
            <button
              className="text-blue-500 hover:text-blue-600"
              onClick={(e) => {
                e.stopPropagation();
                handleParticipantSelect(member);
              }}
            >
              Add
            </button>
          </div>
        ))}
        {!filteredMembers?.length && (
          <p className="text-gray-500 text-center py-4">
            No participants found.
          </p>
        )}
      </div>

      {/* Selected Participants */}
      <div className="max-h-[20vh] overflow-y-auto">
        <h3 className="text-lg font-semibold mb-3">Selected Participants</h3>
        <div className="flex flex-wrap gap-3">
          {selectedParticipantsList.map((participantId) => {
            const participant = memberWithCalculatedBudget.find(
              (m) => m._id === participantId
            );
            return (
              <div
                key={participantId}
                className="flex items-center bg-blue-100 text-blue-700 p-1 text-sm rounded-lg shadow-sm"
              >
                <span className="mr-2">{participant?.name}</span>
                <button
                  onClick={() => handleRemoveParticipant(participantId)}
                  className="text-red-500 hover:text-red-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            );
          })}
          {!selectedParticipantsList.length && (
            <p className="text-gray-500">No participants selected.</p>
          )}
        </div>
      </div>

      {/* Save and Add Member Buttons */}
      <div className="text-right space-x-3">
        <button
          onClick={() => {
            setIsAddParticipantModalOpen(false);
            navigate('/direct-support/participant/add', {
              state: { fromParticipantButtonClick: true },
            });
          }}
          className="px-4 py-2 rounded-lg shadow-md text-white bg-green-600 hover:bg-green-700 transition duration-200"
        >
          Add New Member
        </button>
        <button
          onClick={handleSaveParticipants}
          disabled={isSaving || !selectedParticipantsList.length}
          className={`px-4 py-2 rounded-lg shadow-md text-white ${
            isSaving
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700'
          } transition duration-200`}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </button>
      </div>
    </div>
  );

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('', {
      id: 'S.No',
      cell: (info) => <span>{info.row.index + 1}</span>,
      header: 'S.No',
    }),

    columnHelper.accessor('name', {
      cell: (info) => (
        <span className="min-w-40 inline-block truncate">
          {info.getValue()}
        </span>
      ),
      header: 'Participant Name',
      size: 180,
    }),
    columnHelper.accessor('ndis', {
      cell: (info) => <span>{info.getValue()}</span>,
      header: 'NDIS',
    }),
    columnHelper.accessor('budgetStatus', {
      cell: (info) => (
        <span
          className={`whitespace-nowrap ${
            info.getValue() === 'Overspending'
              ? 'text-red-500'
              : info.getValue() === 'Underspending'
              ? 'text-blue-500'
              : 'text-slate-600'
          }`}
        >
          {info.getValue() === 'Overspending'
            ? 'Over Utilised'
            : info.getValue() === 'Underspending'
            ? 'Under Utilised'
            : 'No Data'}
        </span>
      ),
      header: 'Budget Status',
    }),
    columnHelper.accessor('community', {
      cell: (info) => {
        const serviceType = info.row.original.serviceType;
        const community = info.getValue();
        const showSA =
          serviceType === 'support_coordination' || serviceType === 'both';

        return (
          <span
            className={`rounded-md bg-opacity-10 py-1 px-3 text-sm font-medium w-fit ${
              community === 'A'
                ? 'bg-green-400 text-green-600'
                : community === 'B'
                ? 'bg-cyan-400 text-cyan-600'
                : community === 'C'
                ? 'bg-violet-400 text-violet-600'
                : 'bg-pink-400 text-pink-600'
            }`}
          >
            {showSA ? `${community ? `${community}, SC` : 'SC'}` : community}
          </span>
        );
      },
      header: 'Community',
    }),
    columnHelper.accessor('participantAttendance', {
      header: 'Attendance',
      cell: (info) => {
        const currentDate = new Date();
        const startDate = new Date(eventDetails?.startDate);
        const isDateDisabled = startDate >= currentDate;
        const isStatusDisabled = !eventDetails?.status;
        const isDisabled = isDateDisabled || isStatusDisabled;
        const participantId = info.row.original._id;

        return (
          <div className="relative group">
            <select
              value={participantAttendance[participantId]?.attendance || ''}
              onChange={(e) =>
                handleAttendanceChange(participantId, e.target.value)
              }
              disabled={isDisabled}
              className={`px-3 py-1 w-32 rounded-md text-sm ${
                isDisabled
                  ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                  : 'bg-white border border-gray-300'
              }`}
            >
              <option value="">Select</option>
              <option value="Attended">Attended</option>
              <option value="Not Attended">Not Attended</option>
            </select>
            {isDisabled && (
              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block">
                <div className="bg-gray-800/80 text-white rounded py-1 px-3 text-sm whitespace-nowrap">
                  {isStatusDisabled
                    ? 'Event is not active'
                    : 'Will be enabled on the event day'}
                </div>
              </div>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('attendedHours', {
      header: 'Hours Attended',
      cell: (info) => {
        const currentDate = new Date();
        const startDate = new Date(eventDetails?.startDate);
        const isDateDisabled = startDate >= currentDate;
        const isStatusDisabled = !eventDetails?.status;
        const isDisabled = isDateDisabled || isStatusDisabled;
        const participantId = info.row.original._id;
        const isAttended =
          participantAttendance[participantId]?.attendance === 'Attended';
        const showRedBorder =
          isAttended && !participantAttendance[participantId]?.attendedHours;

        return (
          <div className="relative group">
            <select
              value={participantAttendance[participantId]?.attendedHours || ''}
              onChange={(e) => handleHoursChange(participantId, e.target.value)}
              disabled={isDisabled || !isAttended}
              className={`px-3 py-1 w-24 rounded-md text-sm ${
                isDisabled || !isAttended
                  ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                  : 'bg-white border'
              } ${showRedBorder ? 'border-red-500' : 'border-gray-300'}`}
            >
              <option value="">Select</option>
              {[...Array(eventDetails?.duration || 0)].map((_, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
        );
      },
    }),
    columnHelper.accessor('visits', {
      cell: (info) => (
        <div className="flex items-center space-x-3.5">
          <IoEyeOutline
            role="button"
            onClick={() =>
              navigate(`/direct-support/participant/${info.row.original._id}`)
            }
          />
        </div>
      ),
      header: 'View',
    }),
  ];

  const remainingParticipants =
    eventDetails?.noOfRequiredParticipants -
    eventDetails?.participantsIds?.length;

  if (loading) return <Loading />;
  if (isError) return <p>Error loading members...</p>;

  return (
    <div className="rounded-lg shadow-lg p-4 sm:p-8 space-y-6 bg-white border border-gray-200 mt-6">
      <div className="flex justify-between items-center flex-wrap gap-2">
        <div className="text-sm font-medium bg-blue-100 text-blue-700 px-4 py-2 rounded-full">
          Required Participants: {eventDetails?.noOfRequiredParticipants}
        </div>
        <div
          className={`text-sm font-medium px-4 py-2 rounded-full ${
            remainingParticipants < 0
              ? 'bg-red-100 text-red-700'
              : remainingParticipants === 0
              ? 'bg-green-100 text-green-700'
              : 'bg-blue-100 text-blue-700'
          }`}
        >
          {remainingParticipants < 0
            ? `Extra Members Selected: ${Math.abs(remainingParticipants)}`
            : remainingParticipants === 0
            ? 'All Members Selected'
            : `Remaining Participants: ${remainingParticipants}`}
        </div>

        <button
          disabled={!eventDetails?.status}
          onClick={() => setIsAddParticipantModalOpen(true)}
          className={`${
            !eventDetails?.status
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-500 hover:bg-blue-600'
          } text-white px-4 py-1.5 rounded-md`}
        >
          {eventDetails?.participantsIds?.length > 0
            ? 'Update Participant List'
            : 'Add Participant'}
        </button>
      </div>

      <div>
        {selectedParticipantsDetails?.length ? (
          <ReusableTable
            columns={columns}
            tableData={selectedParticipantsDetails}
          />
        ) : (
          <p className="text-gray-600 text-center  bg-gray-200 px-10 py-2 rounded-lg">
            No participants selected.
          </p>
        )}
      </div>

      <ModalWithContent
        isOpen={isAddParticipantModalOpen}
        setIsOpen={setIsAddParticipantModalOpen}
        content={modalContent}
      />
    </div>
  );
};

Participants.propTypes = {
  memberWithCalculatedBudget: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      ndis: PropTypes.string.isRequired,
      community: PropTypes.string,
      serviceType: PropTypes.string,
    })
  ),
  eventDetails: PropTypes.shape({
    participantsIds: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    ),
    noOfRequiredParticipants: PropTypes.number,
  }),
};

export default React.memo(Participants);
