import React, { Suspense } from 'react';
import DirectSupportMenu from '../DirectSupportMenu';
import useFetchInsight from './../../../hooks/useFetchInsight';
const ParticipantStat = React.lazy(() =>
  import('../../../components/DSDashboard/ParticipantStat')
);
const InsightCategoryStat = React.lazy(() =>
  import('../../../components/DSDashboard/InsightCategoryStat')
);
const InsightResolveStat = React.lazy(() =>
  import('../../../components/DSDashboard/InsightResolveStat')
);
const ActionStat = React.lazy(() =>
  import('../../../components/DSDashboard/ActionStat')
);

const DirectSupportDashboard = () => {
  const { data: insights = [], isLoading, error } = useFetchInsight();
  return (
    <div>
      <DirectSupportMenu />
      <Suspense fallback={<div className="text-center py-5">Loading...</div>}>
        <ParticipantStat />
      </Suspense>
      {insights.length > 0 ? (
        <>
          <Suspense
            fallback={<div className="text-center py-5">Loading...</div>}
          >
            <InsightCategoryStat />
          </Suspense>
          <Suspense
            fallback={<div className="text-center py-5">Loading...</div>}
          >
            <InsightResolveStat />
          </Suspense>
        </>
      ) : (
        <>
          <h1 className="uppercase tracking-widest text-gray-500 mt-16 text-center">
            No insights available
          </h1>
        </>
      )}
      <Suspense fallback={<div className="text-center py-5">Loading...</div>}>
        <ActionStat />
      </Suspense>
    </div>
  );
};

export default React.memo(DirectSupportDashboard);
