import React from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { GrAdd } from 'react-icons/gr';

const UserSettingCard = ({ setSelectedComponent }) => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-28 shadow-md rounded-md mt-10 md:mt-0">
      <button
        onClick={() => setSelectedComponent('addUser')}
        className="w-[97%] h-10 group flex items-center justify-between gap-4 rounded-lg border-dashed border-indigo-600 bg-indigo-600 p-2 transition-colors hover:bg-transparent hover:border-2 hover:border-dashed hover:border-indigo-600 focus:outline-none focus:ring"
      >
        <span className=" font-medium text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500">
          Add User
        </span>

        <span className="shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500">
          <GrAdd />
        </span>
      </button>
      <button
        onClick={() => setSelectedComponent('showUsers')}
        className="w-[97%] h-10 mt-2 group flex items-center justify-between gap-4 rounded-lg border-dashed border-violet-600 bg-violet-600 p-2 transition-colors hover:bg-transparent hover:border-2 hover:border-dashed hover:border-violet-600  focus:outline-none focus:ring"
        href="#"
      >
        <span className=" font-medium text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500">
          All Users
        </span>

        <span className="shrink-0 rounded-full border border-current bg-white p-2 text-indigo-600 group-active:text-indigo-500">
          <AiOutlineEye />
        </span>
      </button>
    </div>
  );
};

export default UserSettingCard;
