import React from 'react';
import { NavLink } from 'react-router-dom';

const menuItems = [
  { path: '/direct-support/dashboard', label: 'Dashboard' },
  { path: '/direct-support/insight', label: 'Insight' },
  { path: '/direct-support/participants', label: 'Participants' },
  { path: '/direct-support/settings', label: 'Settings' },
];

const DirectSupportMenu = () => {
  return (
    <nav className="bg-white/80 backdrop-blur-sm rounded-lg shadow-sm p-2 md:p-4 mb-5">
      <div className="flex gap-2 md:gap-6 flex-wrap">
        {menuItems.map(({ path, label }) => (
          <NavLink
            key={path}
            to={path}
            className={({ isActive }) =>
              `text-sm md:text-base px-2  md:px-4 py-1 md:py-2 rounded-md font-medium transition-all duration-200 ${
                isActive
                  ? 'bg-violet-100 text-violet-700'
                  : 'text-gray-600 hover:bg-gray-100 hover:text-violet-600'
              }`
            }
          >
            {label}
          </NavLink>
        ))}
      </div>
    </nav>
  );
};

export default React.memo(DirectSupportMenu);
