export const getPositionDepartment = (pos, dep) => {
  var position = "";
  var department = "";

  switch (pos) {
    case "tl":
      position = "Team Leader";
      break;
    case "dsPM":
      position = "Direct Supports Program Manager";
      break;
    case "sdm":
      position = "Service Delivery Manager";
      break;
    case "ceo":
      position = "CEO";
      break;
    case "cfo":
      position = "CFO";
      break;
    case "opsManager":
      position = "Operations Manager";
      break;

    default:
      position = "Team Member";
      break;
  }
  switch (dep) {
    case "directSupport":
      department = "Direct Supports";
      break;
    case "serviceDelivery":
      department = "Service Delivery Department";
      break;
    case "finance":
      department = "Finance Department";
      break;
    case "tle":
      department = "Top Level Executive";
      break;
    default:
      department = "Decent Care";
      break;
  }

  return { position, department };
};
