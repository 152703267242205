import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { CiEdit } from 'react-icons/ci';
import { FaArrowTrendDown, FaArrowTrendUp } from 'react-icons/fa6';
import { MdErrorOutline } from 'react-icons/md';
import calculateProgress from '../../utils/calculateProgress';
import { formatDate } from '../../utils/DateFormation';
import NumberStat from '../card/numberStat/NumberStat';
import EditDetailsForm from '../forms/editEventDetailsFrom/EditDetailsForm';
import ModalWithContent from '../modal/ModalWithContent';

// Extract reusable card component
const BudgetCard = ({ gradient, title, value, textColor }) => (
  <div
    className={`bg-gradient-to-r ${gradient} p-4 sm:p-6 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300`}
  >
    <h3 className={`text-sm lg:text-base font-semibold mb-3 ${textColor}`}>
      {title}
    </h3>
    <h2
      className={`text-3xl xl:text-4xl font-extrabold ${textColor.replace(
        '-600',
        '-800'
      )} mt-2`}
    >
      {value}
    </h2>
  </div>
);

const EventDescription = ({ eventDetails, selectedParticipantsDetails }) => {
  // Move calculations to the top
  const expenses = useMemo(
    () => eventDetails?.items?.reduce((acc, item) => acc + item.cost, 0) || 0,
    [eventDetails?.items]
  );

  // Format numbers consistently
  const formatCurrency = (amount) =>
    `$${amount.toLocaleString('en-US', { minimumFractionDigits: 2 })}`;

  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState(false);
  const [editType, setEditType] = useState('');

  const budgetStatusCounts = useMemo(() => {
    if (!selectedParticipantsDetails)
      return { Underspending: 0, Overspending: 0, 'No Data': 0 };

    return selectedParticipantsDetails.reduce(
      (acc, participant) => {
        const status = participant.budgetStatus || 'No Data';
        acc[status] = (acc[status] || 0) + 1;
        return acc;
      },
      { Underspending: 0, Overspending: 0, 'No Data': 0 }
    );
  }, [selectedParticipantsDetails]);

  return (
    <div className="rounded-lg shadow-lg p-4 sm:p-8 space-y-8 mt-6 bg-white border border-gray-200">
      {/* Financial Section */}
      <div>
        <h2 className="text-2xl font-extrabold text-gray-700 mb-6">Overview</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:gap-6 xl:grid-cols-4 gap-6">
          <BudgetCard
            gradient="from-green-100 to-green-50"
            title="Budget"
            value={formatCurrency(eventDetails.budget)}
            textColor="text-green-600"
          />
          <BudgetCard
            gradient="from-red-100 to-red-50"
            title="Expenses"
            value={formatCurrency(expenses)}
            textColor="text-red-600"
          />
          <BudgetCard
            gradient="from-blue-100 to-blue-50"
            title="Remaining"
            value={formatCurrency(eventDetails.budget - expenses)}
            textColor="text-blue-600"
          />
          <BudgetCard
            gradient="from-yellow-100 to-yellow-50"
            title="Completion Status"
            value={`${calculateProgress({ eventDetails }).percentage}%`}
            textColor="text-yellow-600"
          />
        </div>
      </div>

      {/* Participant Budget Status Section */}
      <div className="mt-6">
        <h2 className="text-2xl font-extrabold text-gray-700 mb-6">
          Participant Budget Status
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          <NumberStat
            value={budgetStatusCounts.Underspending}
            label="Under Utilised"
            icon={<FaArrowTrendDown />}
            iconBackground="text-xl bg-green-300"
            textColor="text-green-600"
            cardBackground="bg-gradient-to-r from-green-100 to-green-50"
          />
          <NumberStat
            value={budgetStatusCounts.Overspending}
            label="Over Utilised"
            icon={<FaArrowTrendUp />}
            iconBackground="text-xl bg-red-300"
            textColor="text-red-600"
            cardBackground="bg-gradient-to-r from-red-100 to-red-50"
          />
          <NumberStat
            value={budgetStatusCounts['No Data']}
            label="No Data"
            icon={<MdErrorOutline />}
            iconBackground="text-xl bg-blue-300"
            textColor="text-blue-600"
            cardBackground="bg-gradient-to-r from-blue-100 to-blue-50"
          />
        </div>
      </div>

      {/* Time Section */}
      <div>
        <h2 className="text-2xl font-extrabold text-gray-700 mb-6">
          Event Timeline
        </h2>
        <div className="grid grid-cols-1 sm lg:grid-cols-3 gap-6">
          <div className="bg-gray-50 p-6 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300">
            <h3 className="text-sm font-semibold text-gray-600">Start Date</h3>
            <p className="text-lg font-medium text-gray-800 mt-2">
              {formatDate(eventDetails.startDate)}
            </p>
          </div>
          {eventDetails?.endDate && (
            <div className="bg-gray-50 p-6 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300">
              <h3 className="text-sm font-semibold text-gray-600">End Date</h3>
              <p className="text-lg font-medium text-gray-800 mt-2">
                {formatDate(eventDetails.endDate)}
              </p>
            </div>
          )}
          <div className="bg-gray-50 p-6 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300">
            <h3 className="text-sm font-semibold text-gray-600">Duration</h3>
            <p className="text-lg font-medium text-gray-800 mt-2">
              {eventDetails?.duration} hours
            </p>
          </div>
        </div>
      </div>

      {/* Description and Plan Section */}
      <div className="space-y-6">
        <div className="bg-gray-50 p-6 rounded-lg shadow-sm relative">
          <h3 className="text-sm font-semibold text-gray-600 mb-3">
            Event Description
          </h3>
          <p className="text-base text-gray-700 leading-relaxed">
            {eventDetails.eventDescription}
          </p>
          <CiEdit
            onClick={() => {
              setIsEditDetailsModalOpen(true);
              setEditType('eventDescription');
            }}
            className="absolute top-2 right-3 hover:text-gray-800 text-gray-500 cursor-pointer text-2xl"
          />
        </div>
        <div className="bg-gray-50 p-6 rounded-lg shadow-sm relative">
          <h3 className="text-sm font-semibold text-gray-600 mb-3">
            Event Plan
          </h3>
          <p className="text-base text-gray-700 leading-relaxed">
            {eventDetails.eventPlan}
          </p>
          <CiEdit
            onClick={() => {
              setIsEditDetailsModalOpen(true);
              setEditType('eventPlan');
            }}
            className="absolute top-2 right-3 hover:text-gray-800 text-gray-500 cursor-pointer text-2xl"
          />
        </div>
      </div>
      <ModalWithContent
        content={
          <EditDetailsForm
            setIsEditDetailsModalOpen={setIsEditDetailsModalOpen}
            editType={editType}
            setEditType={setEditType}
            eventDetails={eventDetails}
          />
        }
        isOpen={isEditDetailsModalOpen}
        setIsOpen={setIsEditDetailsModalOpen}
      />
    </div>
  );
};

EventDescription.propTypes = {
  eventDetails: PropTypes.shape({
    budget: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    duration: PropTypes.number.isRequired,
    eventDescription: PropTypes.string.isRequired,
    eventPlan: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        cost: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
};

export default React.memo(EventDescription);
