import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api/api';
import { formatDate } from '../../utils/DateFormation';
import getUserData from '../../utils/getUserData';
import NumberStat from '../card/numberStat/NumberStat';
import Modal from '../modal/Modal';

const ProjectDetailsComponent = ({ projectDetails }) => {
  const {
    projectName,
    description,
    status,
    startDate,
    endDate,
    departments,
    budget,
    completionPercentage,
    stakeHolders,
    approvalStatus,
  } = projectDetails;

  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const user = getUserData();

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteItemModalOpen, setDeleteItemModalOpen] = useState(false);

  const deleteItem = async () => {
    if (isDeleting) return;
    try {
      setIsDeleting(true);
      const response = await api.delete(`/projects/${id}`, {});

      if (response) {
        toast.success('Event Item deleted successfully!');
        queryClient.invalidateQueries('projects');
        navigate('/projects');
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error('Failed to delete item. Please try again.');
    } finally {
      setIsDeleting(false);
      setDeleteItemModalOpen(false);
    }
  };

  const isUserStakeholder = () => {
    return stakeHolders.some((stakeholder) => stakeholder.userId === user._id);
  };

  return (
    <div className="mt-2 p-3 sm:p-6 bg-white rounded-lg shadow-lg">
      {/* Header Section */}
      <div className="mb-6">
        <div className="flex justify-between flex-wrap">
          <h1 className="text-3xl xl:text-4xl font-bold text-gray-800">
            {projectName}
          </h1>
          <div className="flex items-center gap-2">
            {user?.role === 'admin' && (
              <button
                onClick={() => setDeleteItemModalOpen(true)}
                className="bg-red-600 hover:bg-red-700 px-4 py-1 text-white rounded-lg"
              >
                Delete
              </button>
            )}
            {(isUserStakeholder() || user?.role === 'admin') && (
              <button
                onClick={() => navigate(`/project/edit/${id}`)}
                className="bg-blue-600 hover:bg-blue-700 px-4 py-1 text-white rounded-lg"
              >
                Edit
              </button>
            )}
          </div>
        </div>
        <div className="mt-2 flex items-center gap-2">
          <span className="px-3 py-1 text-sm rounded-full bg-blue-100 text-blue-800">
            {status}
          </span>
          {approvalStatus ? (
            <span className="px-3 py-1 text-sm rounded-full bg-blue-100 text-blue-800">
              Approved
            </span>
          ) : (
            <span className="px-3 py-1 text-sm rounded-full bg-red-100 text-red-800">
              Not Approved
            </span>
          )}
        </div>
      </div>

      {/* Progress Bar */}
      <div className="mb-6">
        <div className="flex items-center justify-between mb-2">
          <span className="text-sm font-medium text-gray-600">Progress</span>
          <span className="text-sm font-medium text-gray-600">
            {completionPercentage}%
          </span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-green-600 h-2.5 rounded-full"
            style={{ width: `${completionPercentage}%` }}
          ></div>
        </div>
      </div>

      {/* Replace the Project Details Grid with NumberStats */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 mb-6">
        <NumberStat
          value={formatDate(startDate)}
          label="Start Date"
          icon={<FaRegCalendarAlt />}
          iconBackground="bg-blue-100"
          textColor="text-blue-600"
          cardBackground="bg-blue-50"
          textSize="text-2xl"
        />
        <NumberStat
          value={formatDate(endDate)}
          label="End Date"
          icon={<FaRegCalendarAlt />}
          iconBackground="bg-green-100"
          textColor="text-green-600"
          cardBackground="bg-green-50"
          textSize="text-2xl"
        />
        <NumberStat
          value={`$${budget}`}
          label="Budget"
          icon={<BsCurrencyDollar />}
          iconBackground="bg-purple-100"
          textColor="text-purple-600"
          cardBackground="bg-purple-50"
          textSize="text-2xl"
        />
      </div>

      {/* Description Section */}
      <div className="my-10 p-2">
        <div
          className="prose prose-sm prose-headings:font-bold prose-headings:text-gray-800 prose-ul:list-disc prose-ol:list-decimal prose-li:text-gray-600 prose-h1:my-3 prose-h2:my-2 prose-h3:my-1 prose-p:my-1 prose-ul:my-0 prose-ol:my-0 prose-li:my-0 text-gray-600"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>

      {/* Departments Section */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          Departments
        </h2>
        <div className="flex flex-wrap gap-3">
          {departments.map((department, index) => (
            <div
              key={index}
              className="px-4 py-2 rounded-lg bg-gradient-to-r from-indigo-50 to-blue-50 border border-blue-100 shadow-sm"
            >
              <div className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-full bg-blue-500"></div>
                <span className="text-gray-700 font-medium">{department}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Stakeholders Section */}
      <div>
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          Stakeholders
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
          {stakeHolders.map((stakeholder) => (
            <div
              key={stakeholder._id}
              className="flex items-center gap-3 bg-white p-3 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="flex-shrink-0 w-10 h-10 xl:w-12 xl:h-12 bg-blue-100 rounded-full flex items-center justify-center">
                <span className="text-blue-600 font-medium text-lg xl:text-2xl">
                  {stakeholder.name.charAt(0).toUpperCase()}
                </span>
              </div>
              <div className="flex-grow min-w-0">
                <h4 className="font-medium text-gray-900 truncate">
                  {stakeholder.name}
                </h4>
                <p className="text-sm text-gray-500 truncate">
                  {stakeholder.email}
                </p>
                <div className="mt-2 sm:mt-0">
                  <span className="text-sm text-gray-600">
                    {stakeholder.position} • {stakeholder.department}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        btnName={isDeleting ? 'Deleting...' : 'Delete Project'}
        title="Delete Project?"
        message="Are you sure you want to delete this project? This action cannot be undone."
        isOpen={deleteItemModalOpen}
        setIsOpen={setDeleteItemModalOpen}
        exitBtnAction={() => setDeleteItemModalOpen(false)}
        btnAction={() => deleteItem()}
      />
    </div>
  );
};

export default ProjectDetailsComponent;
