export const getPlanDetails = (planStartDate, planEndDate) => {


  // Convert the start and end dates into Date objects
  const startDate = new Date(planStartDate);
  const endDate = new Date(planEndDate);

  // Format the start date as dd/mm/yyyy
  const startDay = String(startDate.getDate()).padStart(2, '0');
  const startMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
  const startYear = startDate.getFullYear();
  const formattedStartDate = `${startDay}/${startMonth}/${startYear}`;

  // Format the end date as dd/mm/yyyy
  const endDay = String(endDate.getDate()).padStart(2, '0');
  const endMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
  const endYear = endDate.getFullYear();
  const formattedEndDate = `${endDay}/${endMonth}/${endYear}`;

  // Get the current date
  const currentDate = new Date();

  // Calculate the remaining duration in months
  const remainingDurationInMonths = parseFloat(((endDate - currentDate) / (1000 * 60 * 60 * 24 * 30)).toFixed(1));

  return {
    formattedStartDate,
    formattedEndDate,
    remainingDurationInMonths
  };

}