import { useQuery } from '@tanstack/react-query';
import api from '../api/api';


const useGetAllUser = () => {
    return useQuery({
        queryKey: ['all_user'],
        queryFn: async () => {
            const response = await api.get('/user');
            return response.data;
        },
    });
}

export default useGetAllUser;
