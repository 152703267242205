import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render shows the fallback UI
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Log the error details for debugging
        console.error("ErrorBoundary caught an error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Optionally log the error or send it to a monitoring service.
            return <>{this.props.fallback || null}</>; // Render fallback UI or nothing
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
