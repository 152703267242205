import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { MdEvent } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api/api';
import BreadCrumb from '../../breadCrumb/BreadCrumb';
import Modal from '../../modal/Modal';
import Input from '../formElements/inputs/Input';
import LoadingButton from '../formElements/loadingButton/LoadingButton';
import SelectInput from '../formElements/slectInput/SelectInput';
import SubmitButton from '../formElements/submitButton/SubmitButton';
import TextArea from '../formElements/textArea/TextArea';

const AddEventForm = () => {
  let [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [budget, setBudget] = useState('');
  const [eventName, setEventName] = useState('');
  const [type, setType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [duration, setDuration] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [eventPlan, setEventPlan] = useState('');

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newEvent) => {
      try {
        return await api.post('/events', newEvent);
      } catch (err) {
        throw err;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['events'] });
      toast.success('Event Added Successfully');
      setIsOpen(true);
      setEventName('');
      setBudget('');
      setType('');
      setStartDate('');
      setEndDate('');
      setDuration('');
      setEventDescription('');
      setEventPlan('');
    },
    onError: (error) => {
      console.error('Error in mutation onError:', error);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (type === 'Recurring' && new Date(startDate) > new Date(endDate)) {
      toast.error('Start date cannot be after end date');
      return;
    }

    const newEvent = {
      eventName,
      budget,
      type,
      startDate,
      endDate: type === 'Recurring' ? endDate : null,
      duration,
      eventDescription,
      eventPlan,
    };

    setLoading(true);
    try {
      await mutation.mutateAsync(newEvent);
    } catch (error) {
      const errorMsg =
        error.response?.data?.message || 'An unexpected error occurred.';
      console.error('Caught in handleSubmit:', error);
      toast.error(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  const navigation = () => {
    navigate('/events');
  };

  return (
    <>
      <BreadCrumb
        currentPage="Add Event"
        prevPage="Events"
        icon={<MdEvent />}
        navigation={navigation}
      />
      <div className="mt-2">
        <div className="w-full shadow-md rounded-md">
          <form action="" onSubmit={handleSubmit}>
            <div className="w-full h-14 bg-slate-200">
              <h3 className="text-xl p-4 font-bold text-slate-600">Event</h3>
            </div>
            <div className="flex flex-col items-center">
              <Input
                id="eventName"
                value={eventName}
                change={setEventName}
                placeholder="Event Name"
              />

              <Input
                id="budget"
                value={budget}
                change={setBudget}
                placeholder="Budget"
                type="number"
              />

              <SelectInput
                value={type}
                change={setType}
                id="type"
                placeholder="Event Type"
                options={[
                  { value: 'Once Off', name: 'Once Off' },
                  { value: 'Recurring', name: 'Recurring' },
                ]}
              />

              <Input
                id="startDate"
                value={startDate}
                change={setStartDate}
                placeholder="Start Date"
                type="date"
              />
              {type === 'Recurring' && (
                <>
                  <Input
                    id="endDate"
                    value={endDate}
                    change={setEndDate}
                    placeholder="End Date"
                    type="date"
                    className="text-gray-700"
                  />
                </>
              )}

              <Input
                id="duration"
                value={duration}
                change={setDuration}
                placeholder="Duration In Hours"
                type="number"
              />

              <TextArea
                id="eventDescription"
                value={eventDescription}
                change={setEventDescription}
                placeholder="Event Description"
              />

              <TextArea
                id="eventPlan"
                value={eventPlan}
                change={setEventPlan}
                placeholder="Event Plan"
              />

              {loading ? (
                <LoadingButton />
              ) : (
                <SubmitButton
                  btnName="Add Event"
                  icon={<AiOutlineArrowRight />}
                />
              )}
            </div>
          </form>
        </div>
      </div>
      <Modal
        btnName="Add Another Event"
        title="Event Added 🎉"
        message="Event added to the system successfully. Would you like to add another event?"
        navigation={navigation}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default AddEventForm;
