export const calculateTotalFunding = (funding, type) => {
    // Ensure funding is always an array
    var budgets = Array.isArray(funding)
        ? funding.filter((budget) => budget?.group?.toLowerCase() === type)
        : [];

    let totalBudget = 0;
    let totalRemaining = 0;

    // Iterate over the budgets array (which is guaranteed to be an array now)
    for (let item of budgets) {
        totalBudget += item.budget || 0; // Ensure it handles undefined budget values
        totalRemaining += item.remaining || 0; // Ensure it handles undefined remaining values
    }

    let totalUsed = totalBudget - totalRemaining;

    return {
        totalBudget,
        totalRemaining,
        totalUsed
    };
}
