import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import ReusableTable from '../../table/ReusableTable';
const MemberEventCard = ({ singleMember }) => {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('eventName', {
        header: 'Event Name',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('eventType', {
        header: 'Event Type',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('eventStartDate', {
        header: 'Start Date',
        cell: (info) => new Date(info.getValue()).toLocaleDateString(),
      }),
      columnHelper.accessor('attendance', {
        header: 'Attendance',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('attendedHours', {
        header: 'Attendance Hours',
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor('eventId', {
        header: 'View Event',
        cell: (info) => (
          <IoEyeOutline
            role="button"
            onClick={() => navigate(`/event/${info.getValue()}`)}
          />
        ),
      }),
    ],
    [columnHelper, navigate]
  );

  return (
    <div className="rounded-sm border border-stroke bg-white p-3 px-7.5 shadow-md dark:border-strokedark dark:bg-boxdark mt-10">
      <div className="mb-5 ml-3">
        <h5 className="text-xl text-slate-600 font-semibold">Member Events</h5>
      </div>
      {singleMember?.events?.length > 0 ? (
        <ReusableTable tableData={singleMember?.events} columns={columns} />
      ) : (
        <p className="mt-5 text-center max-w-lg mx-auto py-2 px-4 bg-gray-100 rounded-md">
          No event data
        </p>
      )}
    </div>
  );
};

export default React.memo(MemberEventCard);
