import { useQuery, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { MdEvent } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api/api';
import BreadCrumb from '../../components/breadCrumb/BreadCrumb';
import ErrorBoundaryMessage from '../../components/errorBoundaryMessage/ErrorBoundaryMessage';
import EventDescription from '../../components/EventDetails/EventDescription';
import EventItems from '../../components/EventDetails/EventItems';
import EventRevenue from '../../components/EventDetails/EventRevenue';
import EventTasks from '../../components/EventDetails/EventTasks';
import Participants from '../../components/EventDetails/Participants';
import Modal from '../../components/modal/Modal';
import ErrorBoundary from '../../utils/ErrorBoundary';
import Loading from '../../widgets/loading/Loading';
import useGetMemberWithBudget from './../../hooks/useGetMemberWithBudget';

const EventDetails = ({ members = [] }) => {
  const queryClient = useQueryClient();
  const { id } = useParams();

  const {
    data: eventDetails = {},
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ['events', id],
    queryFn: async () => {
      const response = await api.get(`/events/${id}`);
      return response.data;
    },
  });

  // state starts
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // state ends

  const markAsCompleted = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      const response = await api.put(`/events/${id}`, {
        type: 'markEventAsCompleted',
      });

      if (response.status === 200) {
        toast.success('Event marked as completed successfully!');
        queryClient.invalidateQueries(['events', id]);
      } else {
        throw new Error('Failed to update event status');
      }
    } catch (error) {
      console.error('Error updating event status:', error);
      toast.error(
        error.response?.data?.message || 'Failed to mark event as completed'
      );
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };
  const navigate = useNavigate();
  const navigation = () => {
    navigate('/events');
  };

  const {
    memberWithCalculatedBudget,
    loading: isMembersLoading,
    isError,
  } = useGetMemberWithBudget();

  const selectedParticipantsDetails = useMemo(
    () =>
      memberWithCalculatedBudget?.filter((member) =>
        eventDetails?.participantsIds?.includes(member._id)
      ),
    [memberWithCalculatedBudget, eventDetails?.participantsIds]
  );

  return (
    <>
      {loading || isMembersLoading ? (
        <Loading />
      ) : error ? (
        <div className="flex justify-center items-center h-[60vh]">
          <p className="text-xl text-red-500">
            Error loading event details: {error.message}
          </p>
        </div>
      ) : !eventDetails ? (
        <div className="flex justify-center items-center h-[60vh]">
          <p className="text-xl text-gray-500">Event details not found</p>
        </div>
      ) : (
        <section>
          <BreadCrumb
            currentPage="Event Details"
            prevPage="Events"
            icon={<MdEvent />}
            navigation={navigation}
          />
          <ErrorBoundary fallback={<ErrorBoundaryMessage />}>
            <div className="flex justify-between items-center mt-8 md:mt-5 flex-wrap gap-2">
              <h1 className="text-2xl sm:text-3xl text-slate-500 font-bold">
                {eventDetails.eventName || 'Untitled Event'}
              </h1>
              <div>
                {eventDetails?.status ? (
                  <button
                    onClick={() => {
                      const attendance =
                        eventDetails?.participantAttendance || {};
                      if (
                        Object.keys(attendance).length !==
                        eventDetails?.participantsIds?.length
                      ) {
                        toast.error(
                          'Please mark attendance for all participants'
                        );
                        return;
                      }
                      setIsOpen(true);
                    }}
                    disabled={isLoading}
                    className={`${
                      isLoading
                        ? 'bg-blue-400'
                        : 'bg-blue-500 hover:bg-blue-600'
                    } text-white px-4 py-1 rounded-md`}
                  >
                    {isLoading ? 'Processing...' : 'Mark as Completed'}
                  </button>
                ) : (
                  <span className="px-3 py-1.5 bg-gray-500 text-gray-200 rounded-lg">
                    The Event is Inactive
                  </span>
                )}
              </div>
            </div>
          </ErrorBoundary>

          <ErrorBoundary fallback={<ErrorBoundaryMessage />}>
            <EventDescription
              eventDetails={eventDetails || {}}
              selectedParticipantsDetails={selectedParticipantsDetails}
            />
          </ErrorBoundary>
          <ErrorBoundary fallback={<ErrorBoundaryMessage />}>
            <EventItems eventDetails={eventDetails || {}} />
          </ErrorBoundary>
          <ErrorBoundary fallback={<ErrorBoundaryMessage />}>
            <EventTasks eventDetails={eventDetails || {}} />
          </ErrorBoundary>
          <ErrorBoundary fallback={<ErrorBoundaryMessage />}>
            <Participants
              eventDetails={eventDetails || {}}
              selectedParticipantsDetails={selectedParticipantsDetails}
            />
          </ErrorBoundary>
          <ErrorBoundary fallback={<ErrorBoundaryMessage />}>
            <EventRevenue eventDetails={eventDetails || {}} />
          </ErrorBoundary>
        </section>
      )}
      <Modal
        btnName={'Mark as Completed'}
        title="Is the event completed?"
        message="Are you sure you want to make this event completed? This action cannot be undone."
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        exitBtnAction={() => setIsOpen(false)}
        btnAction={() => markAsCompleted()}
      />
    </>
  );
};

// Update PropTypes to make it more flexible
EventDetails.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      // Add other member properties as needed
    })
  ),
};

export default React.memo(EventDetails);
