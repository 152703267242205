import React from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';

const Beta = ({ close }) => {
  return (
    <div className="w-52 sm:w-64 mx-auto text-center py-1 lg:w-72 text-sm">
      <div
        className="py-2 px-3 bg-indigo-800 items-center text-indigo-100 leading-none rounded-full flex lg:inline-flex"
        role="alert"
      >
        <span className="rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3 hidden sm:flex">
          Beta
        </span>
        <span className="font-semibold mr-2 text-left flex-auto">
          Current Version : Beta
        </span>
        <IoMdCloseCircleOutline
          className="flex-shrink-0 text-xl"
          role="button"
          onClick={() => close(false)}
        />
      </div>
    </div>
  );
};

export default Beta;
