import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { FiSettings } from 'react-icons/fi';
import useGetAllUser from '../../hooks/useGetUser';
import BreadCrumb from '../breadCrumb/BreadCrumb';

const AllUser = ({ setSelectedComponent }) => {
  const { data: users, isLoading } = useGetAllUser();

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('', {
      id: 'S.No',
      cell: (info) => <span>{info.row.index + 1}</span>,
      header: 'S.No',
    }),
    columnHelper.accessor('name', {
      cell: (info) => (
        <span className="min-w-40 inline-block">{info.getValue()}</span>
      ),
      header: 'Name',
    }),
    columnHelper.accessor('email', {
      cell: (info) => <span>{info.getValue()}</span>,
      header: 'Email',
    }),
    columnHelper.accessor('department', {
      cell: (info) => <span className="capitalize">{info.getValue()}</span>,
      header: 'Department',
    }),
    columnHelper.accessor('position', {
      cell: (info) => <span className="capitalize">{info.getValue()}</span>,
      header: 'Position',
    }),
    columnHelper.accessor('role', {
      cell: (info) => (
        <span
          className={`rounded-md bg-opacity-10 py-1 px-3 text-sm font-medium capitalize
          ${
            info.getValue() === 'admin'
              ? 'bg-green-400 text-green-600'
              : 'bg-blue-400 text-blue-600'
          }`}
        >
          {info.getValue()}
        </span>
      ),
      header: 'Role',
    }),
  ];

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  function navigation() {
    setSelectedComponent(null);
  }

  return (
    <div>
      <div className="mb-2">
        <BreadCrumb
          currentPage="All Users"
          prevPage="Settings"
          icon={<FiSettings />}
          navigation={navigation}
        />
      </div>

      <div className="p-2 max-w-[100%] mx-auto text-slate-600 fill-gray-400 border rounded-lg shadow-md">
        <div className="overflow-x-auto">
          <table className="border rounded-lg w-full text-left text-slate-600 min-w-[650px]">
            <thead className="bg-slate-200">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className="capitalize px-3.5 py-2">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.length ? (
                table.getRowModel().rows.map((row, i) => (
                  <tr
                    key={row.id}
                    className={`
                    ${i % 2 === 0 ? 'bg-white' : 'bg-slate-50'}
                  `}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="px-1 lg:px-3.5 py-1 lg:py-2">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr className="text-center h-32">
                  <td colSpan={12}>No Records Found!</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex items-center justify-end mt-4 gap-2 px-4 py-2">
          <button
            className="border rounded p-1 px-2 disabled:opacity-50"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className="border rounded p-1 px-2 disabled:opacity-50"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <button
            className="border rounded p-1 px-2 disabled:opacity-50"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className="border rounded p-1 px-2 disabled:opacity-50"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            className="border rounded p-1 w-28 hidden sm:block"
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default AllUser;
