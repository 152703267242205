import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../api/api';
import Input from '../formElements/inputs/Input';

const UpdateCategoryForm = ({ setOpenEditModal, category }) => {
  const [categoryName, setCategoryName] = useState(category?.categoryName);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.put(`/directSupportSettings/${category?._id}`, {
        categoryName: categoryName,
      });
      queryClient.invalidateQueries(['settings']);
      toast.success('Category updated successfully');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Error creating category');
      console.error('Error creating category:', error);
    } finally {
      setLoading(false);
      setOpenEditModal(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="mt-5">
        <Input
          value={categoryName}
          change={setCategoryName}
          type="text"
          id="categoryName"
          placeholder="Category Name"
        />
        <button
          type="submit"
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          {loading ? 'Updating...' : 'Update'}
        </button>
      </form>
    </div>
  );
};

export default React.memo(UpdateCategoryForm);
