import React from 'react';

const BreadCrumb = ({ currentPage, prevPage, navigation, icon }) => {
  return (
    <nav aria-label="Breadcrumb" className="flex">
      <ol className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600">
        <li className="flex items-center">
          <div
            role="button"
            onClick={navigation}
            className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
          >
            {icon}
            <span className="ms-1.5 text-xs font-medium"> {prevPage} </span>
          </div>
        </li>

        <li className="relative flex items-center">
          <span className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"></span>

          <div className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium transition hover:text-gray-900">
            {currentPage}
          </div>
        </li>
      </ol>
    </nav>
  );
};

export default BreadCrumb;
