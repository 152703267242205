import React from 'react';
import { MdInsights } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../components/breadCrumb/BreadCrumb';
import AddInsightForm from '../../../components/forms/addInsightForm/AddInsightForm';

const AddInsight = () => {
  const navigate = useNavigate();
  const navigation = () => {
    navigate('/direct-support/insight');
  };
  return (
    <div>
      <BreadCrumb
        currentPage="Add Insight"
        prevPage="Insight"
        icon={<MdInsights />}
        navigation={navigation}
      />
      <AddInsightForm />
    </div>
  );
};

export default React.memo(AddInsight);
