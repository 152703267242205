const calculateProgress = ({ eventDetails }) => {
    const filteredTasks =
        eventDetails?.tasks?.filter(
            (task) => task.taskStatus !== 'Not Required'
        ) || [];

    const completedTasks = filteredTasks.filter(
        (task) => task.taskStatus === 'Completed'
    ).length;

    const totalTasks = filteredTasks.length;

    return {
        percentage: totalTasks
            ? Math.round((completedTasks / totalTasks) * 100)
            : 0,
        completedTasks,
        totalTasks,
    };
};

export default calculateProgress;
