import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api/api';
import Input from '../formElements/inputs/Input';
import LoadingButton from '../formElements/loadingButton/LoadingButton';
import SubmitButton from '../formElements/submitButton/SubmitButton';
import TextArea from '../formElements/textArea/TextArea';

const EditEventItemForm = ({ setEditItemModalOpen, selectedItem }) => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [itemType, setItemType] = useState(selectedItem?.itemType);
  const [description, setDescription] = useState(selectedItem?.description);
  const [cost, setCost] = useState(selectedItem?.cost);
  const [isExistingItem, setIsExistingItem] = useState(false);

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newItem) => {
      try {
        return await api.put(`/events/${id}`, newItem);
      } catch (err) {
        throw err;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['events', id] });
      toast.success('Item updated successfully');
      setEditItemModalOpen(false);
      setItemType('');
      setDescription('');
      setCost('');
      setIsExistingItem(false);
    },
    onError: (error) => {
      console.error('Error in mutation onError:', error);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newItem = {
      type: 'updateEventItem',
      itemId: selectedItem?._id,
      itemType,
      description,
      cost: isExistingItem ? Number(0) : Number(cost),
    };
    setLoading(true);
    try {
      await mutation.mutateAsync(newItem);
    } catch (error) {
      const errorMsg =
        error.response?.data?.message || 'An unexpected error occurred.';
      console.error('Caught in handleSubmit:', error);
      toast.error(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <div>
          <form action="" onSubmit={handleSubmit}>
            <div className="w-full h-14 bg-slate-200">
              <h3 className="text-xl p-4 font-bold text-slate-600">New Item</h3>
            </div>
            <div className="flex flex-col items-center">
              <div className="mt-4 flex items-center gap-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="itemType"
                    value="new"
                    checked={!isExistingItem}
                    onChange={() => setIsExistingItem(false)}
                  />
                  <span className="ml-2">New Item</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="itemType"
                    value="existing"
                    checked={isExistingItem}
                    onChange={() => setIsExistingItem(true)}
                  />
                  <span className="ml-2">Existing Item</span>
                </label>
              </div>
              <Input
                id="itemType"
                value={itemType}
                change={setItemType}
                placeholder="Item Type"
                type="text"
              />
              <TextArea
                id="description"
                value={description}
                change={setDescription}
                placeholder="Description"
              />

              <Input
                id="cost"
                value={isExistingItem ? 0 : cost}
                change={(value) => {
                  if (!isExistingItem && value >= 0) {
                    setCost(value);
                  } else if (!isExistingItem && value < 0) {
                    setCost('');
                  }
                }}
                placeholder="Cost"
                type="number"
                disabled={isExistingItem}
              />
              {loading ? (
                <LoadingButton />
              ) : (
                <SubmitButton
                  btnName="Update Item"
                  icon={<AiOutlineArrowRight />}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default React.memo(EditEventItemForm);
