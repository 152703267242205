import React from 'react';

const SelectInput = ({
  options,
  id,
  placeholder,
  value,
  change,
  required = true,
}) => {
  return (
    <label
      htmlFor={id}
      className="relative block w-[95%] mt-5 rounded-md border border-gray-200 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
    >
      <select
        value={value} // Use value to control the selected option
        onChange={(e) => change(e.target.value)}
        id={id} // Use id from props
        className="w-full text-slate-500 peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0"
        required={required}
      >
        {/* Placeholder option */}
        <option disabled value="">
          {placeholder}
        </option>
        {options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>

      <span className="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs">
        {placeholder}
      </span>
    </label>
  );
};

export default SelectInput;
