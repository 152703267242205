import useEventRevenue from '../../hooks/useEventRevenue';

const EventRevenue = ({ eventDetails }) => {
  const user = localStorage.getItem('user_data');
  const userData = JSON.parse(user);
  const userInfo = userData?.user;

  const {
    generatedRevenue,
    totalProjectedRevenue,
    belowTarget,
    belowTargetPercentage,
  } = useEventRevenue(eventDetails);

  // Check if user has permission to view revenue details
  const hasPermission =
    userInfo?.department === 'finance' || userInfo?.role === 'admin';

  if (!hasPermission) {
    return (
      <div className="rounded-xl shadow-lg p-6 sm:p-8 space-y-6 mt-6 bg-white border border-gray-100">
        <div>
          <h3 className="text-2xl font-semibold text-gray-800 mb-6">
            Revenue Details
          </h3>
          <div className="grid grid-cols-1 gap-6">
            <div
              className={`p-4 rounded-lg ${
                belowTarget < 0 ? 'bg-red-50' : 'bg-green-50'
              }`}
            >
              <p className="text-sm text-gray-500 mb-1">
                Below Target Percentage
              </p>
              <p
                className={`text-xl font-medium ${
                  belowTargetPercentage < 0 ? 'text-red-600 ' : 'text-green-600'
                }`}
              >
                {belowTargetPercentage !== null
                  ? `${belowTargetPercentage}%`
                  : '-'}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const pendingMessage = 'Will be generated on event date';
  const formatCurrency = (value) =>
    value ? `$${value.toLocaleString()}` : null;

  return (
    <div className="rounded-xl shadow-lg p-6 sm:p-8 space-y-6 mt-6 bg-white border border-gray-100">
      <div>
        <h3 className="text-2xl font-semibold text-gray-800 mb-6">
          Revenue Details
        </h3>
        {!generatedRevenue &&
        !totalProjectedRevenue &&
        !belowTarget &&
        !belowTargetPercentage ? (
          <h1 className="uppercase tracking-widest text-gray-500 my-10 text-center">
            {pendingMessage}
          </h1>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="p-4 bg-gray-50 rounded-lg">
              <p className="text-sm text-gray-500 mb-1">Generated Revenue</p>
              <p className="text-xl font-medium text-gray-900">
                {formatCurrency(generatedRevenue) || '-'}
              </p>
            </div>

            <div className="p-4 bg-gray-50 rounded-lg">
              <p className="text-sm text-gray-500 mb-1">
                Total Projected Revenue
              </p>
              <p className="text-xl font-medium text-gray-900">
                {formatCurrency(totalProjectedRevenue) || '-'}
              </p>
            </div>

            <div
              className={`p-4 rounded-lg ${
                belowTarget < 0 ? 'bg-red-50' : 'bg-green-50'
              }`}
            >
              <p className="text-sm text-gray-500 mb-1">Below Target</p>
              <p
                className={`text-xl font-medium ${
                  belowTarget < 0 ? 'text-red-600 ' : 'text-green-600'
                }`}
              >
                {formatCurrency(belowTarget) || '-'}
              </p>
            </div>

            <div
              className={`p-4 rounded-lg ${
                belowTarget < 0 ? 'bg-red-50' : 'bg-green-50'
              }`}
            >
              <p className="text-sm text-gray-500 mb-1">
                Below Target Percentage
              </p>
              <p
                className={`text-xl font-medium ${
                  belowTargetPercentage < 0 ? 'text-red-600 ' : 'text-green-600'
                }`}
              >
                {belowTargetPercentage !== null
                  ? `${belowTargetPercentage}%`
                  : '-'}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventRevenue;
