import { useState } from 'react';
import Beta from '../../widgets/alert/Beta';
import DirectSupports from './DirectSupports';
const DashBoard = () => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      {isOpen && <Beta close={setIsOpen} />}
      <DirectSupports />
    </>
  );
};

export default DashBoard;
