import { Alert, Button, Form, Input, Spin } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useLogin from '../hooks/useLogin';

const ResetPassword = () => {
  const { token } = useParams(); // retrieve the token from the URL
  const { resetPassword, loading } = useLogin();
  const [error, setError] = useState(null);

  const handleResetPassword = async (values) => {
    setError(null);
    try {
      await resetPassword(token, values.password);
    } catch (err) {
      setError(err.message || 'Failed to reset password. Please try again.');
    }
  };

  return (
    <section className="bg-white">
      <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
        <aside className="relative block h-16 lg:order-last lg:col-span-5 lg:h-full xl:col-span-6">
          <img
            alt="Reset Password Background"
            src="https://images.unsplash.com/photo-1605106702734-205df224ecce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
            className="absolute inset-0 h-full w-full object-cover"
          />
        </aside>

        <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
          <div className="max-w-xl lg:max-w-3xl">
            <h1 className="mt-6 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
              Reset Your Password
            </h1>

            <p className="mt-4 leading-relaxed text-gray-500">
              Enter your new password below to reset your account access.
            </p>

            <Form
              layout="vertical"
              onFinish={handleResetPassword}
              autoComplete="off"
              className="w-full mt-8"
            >
              <Form.Item
                label="New Password"
                name="password"
                rules={[
                  { required: true, message: 'Please enter your new password' },
                  { min: 4, message: 'Password must be at least 4 characters' },
                ]}
              >
                <Input.Password
                  placeholder="Enter new password"
                  size="large"
                  className="rounded-md border-slate-300"
                />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  { required: true, message: 'Please confirm your password' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Passwords do not match')
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm new password"
                  size="large"
                  className="rounded-md border-slate-300"
                />
              </Form.Item>

              {error && (
                <Alert
                  description={error}
                  type="error"
                  showIcon
                  closable
                  className="mb-4"
                />
              )}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="w-full bg-blue-700"
                >
                  {loading ? <Spin /> : 'Reset Password'}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </main>
      </div>
    </section>
  );
};

export default React.memo(ResetPassword);
