import { createColumnHelper } from '@tanstack/react-table';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { TbStatusChange } from 'react-icons/tb';
import calculateProgress from '../../utils/calculateProgress';
import { formatDate } from '../../utils/DateFormation';
import AddEventTaskForm from '../forms/addEventTaskForm/AddEventTaskForm';
import UpdateEventTaskForm from '../forms/updateEventTaskForm/UpdateEventTaskForm';
import ModalWithContent from '../modal/ModalWithContent';
import ReusableTable from '../table/ReusableTable';

const StatusBadge = React.memo(({ status }) => {
  const badgeClass = useMemo(() => {
    switch (status) {
      case 'Not Started':
        return 'bg-gray-100 text-gray-700';
      case 'Inprogress':
        return 'bg-blue-100 text-blue-700';
      case 'Completed':
        return 'bg-green-100 text-green-700';
      case 'Not Required':
        return 'bg-yellow-100 text-yellow-700';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  }, [status]);

  return (
    <span
      className={`px-3 py-1 rounded-full text-sm font-medium min-w-[100px] inline-block text-center ${badgeClass}`}
    >
      {status}
    </span>
  );
});

const EventTasks = ({ eventDetails }) => {
  const [isAddEventTaskModalOpen, setIsAddEventTaskModalOpen] = useState(false);
  const [isUpdateEventTaskModalOpen, setIsUpdateEventTaskModalOpen] =
    useState(false);
  const [selectedTask, setSelectedTask] = useState('');

  const handleTaskUpdate = useCallback((task) => {
    setSelectedTask(task);
    setIsUpdateEventTaskModalOpen(true);
  }, []);

  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor('taskDetails', {
        header: 'Task Details',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('taskStatus', {
        header: 'Status',
        cell: (info) => <StatusBadge status={info.getValue()} />,
      }),
      columnHelper.accessor('dueDate', {
        header: 'Due Date',
        cell: (info) => formatDate(info.getValue()),
      }),
      columnHelper.accessor('completedOn', {
        header: 'Completed On',
        cell: (info) =>
          info.getValue() ? formatDate(info.getValue()) : 'Pending',
      }),

      columnHelper.accessor('_id', {
        cell: (info) => {
          const currentTask = eventDetails?.tasks?.find(
            (task) => task._id === info.getValue()
          );
          return (
            <TbStatusChange
              onClick={() => {
                if (!eventDetails?.status) return;
                handleTaskUpdate(currentTask);
              }}
              className={`${
                !eventDetails?.status
                  ? 'text-gray-400 cursor-not-allowed'
                  : 'text-green-600 hover:text-green-700 cursor-pointer'
              } text-2xl`}
            />
          );
        },
        header: 'Update Status',
      }),
    ],
    [eventDetails?.tasks, eventDetails?.startDate, handleTaskUpdate]
  );

  const progress = useMemo(
    () => calculateProgress({ eventDetails }),
    [eventDetails]
  );

  return (
    <div className="rounded-lg shadow-lg p-4 sm:p-8 space-y-8 mt-6 bg-white border border-gray-200">
      <div className="mb-5 flex justify-between items-center flex-wrap gap-2">
        <h3 className="text-2xl font-extrabold text-gray-700">Event Tasks</h3>
        <button
          onClick={() => {
            setIsAddEventTaskModalOpen(true);
          }}
          className={`${
            !eventDetails?.status
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-500 hover:bg-blue-600'
          } text-white px-4 py-1 rounded-md`}
        >
          Add Task
        </button>
      </div>
      <div className="space-y-2">
        <div className="flex justify-between text-sm text-gray-600">
          <span>Progress ({progress.percentage}%)</span>
          <span>
            {progress.completedTasks} of {progress.totalTasks} tasks completed
          </span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-green-600 h-2.5 rounded-full transition-all duration-500"
            style={{ width: `${progress.percentage}%` }}
          ></div>
        </div>
      </div>
      <div>
        {eventDetails?.tasks?.length > 0 ? (
          <ReusableTable tableData={eventDetails.tasks} columns={columns} />
        ) : (
          <p className="text-gray-600 text-center  bg-gray-200 px-10 py-2 rounded-lg">
            No tasks found
          </p>
        )}
      </div>
      <ModalWithContent
        isOpen={isAddEventTaskModalOpen}
        setIsOpen={setIsAddEventTaskModalOpen}
        content={
          <AddEventTaskForm
            setIsAddEventTaskModalOpen={setIsAddEventTaskModalOpen}
          />
        }
      />
      <ModalWithContent
        isOpen={isUpdateEventTaskModalOpen}
        setIsOpen={setIsUpdateEventTaskModalOpen}
        content={
          <UpdateEventTaskForm
            setIsUpdateEventTaskModalOpen={setIsUpdateEventTaskModalOpen}
            selectedTask={selectedTask}
            setSelectedTask={setSelectedTask}
          />
        }
      />
    </div>
  );
};

EventTasks.propTypes = {
  eventDetails: PropTypes.shape({
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        taskDetails: PropTypes.string.isRequired,
        taskStatus: PropTypes.string.isRequired,
        dueDate: PropTypes.string,
        completedOn: PropTypes.string,
        participantAttendance: PropTypes.string,
      })
    ),
    startDate: PropTypes.string,
    status: PropTypes.bool,
  }).isRequired,
};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

StatusBadge.displayName = 'StatusBadge';

export default React.memo(EventTasks);
