import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { FiSettings } from 'react-icons/fi';
import { toast } from 'react-toastify';
import api from '../../../api/api';
import BreadCrumb from '../../breadCrumb/BreadCrumb';
import Input from '../formElements/inputs/Input';

const AddCategoryForm = ({ setSelectedComponent }) => {
  const [categoryName, setCategoryName] = useState('');

  function navigation() {
    setSelectedComponent(null);
  }

  const queryClient = useQueryClient();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = JSON.parse(localStorage.getItem('user_data'));

    try {
      await api.post('/directSupportSettings', {
        categoryName: categoryName,
        createdBy: userData?.user?.name,
      });
      queryClient.invalidateQueries(['settings']);
      toast.success('Category created successfully');
      navigation();
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Error creating category');
      console.error('Error creating category:', error);
    }
  };

  return (
    <div>
      <BreadCrumb
        currentPage="Add Category"
        prevPage="Direct Support Settings"
        icon={<FiSettings />}
        navigation={navigation}
      />
      <form onSubmit={handleSubmit} className="mt-5">
        <Input
          value={categoryName}
          change={setCategoryName}
          type="text"
          id="categoryName"
          placeholder="Category Name"
        />
        <button
          type="submit"
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Add Category
        </button>
      </form>
    </div>
  );
};

export default React.memo(AddCategoryForm);
