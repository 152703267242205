import { memo, useCallback, useMemo, useState } from 'react';

const SelectUserModalContent = ({
  selectedUsersList,
  setSelectedUsersList,
  onClose,
  users,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleParticipantSelect = useCallback(
    (member) => {
      if (!selectedUsersList.some((user) => user._id === member._id)) {
        setSelectedUsersList((prev) => [...prev, member]);
      }
    },
    [selectedUsersList]
  );

  const handleRemoveParticipant = (userId) => {
    setSelectedUsersList(
      selectedUsersList.filter((user) => user._id !== userId)
    );
  };

  const filteredMembers = useMemo(
    () =>
      users?.filter(
        (member) =>
          member.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          member.email.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [users, searchQuery]
  );

  return (
    <div className="space-y-6">
      {/* Search Input */}
      <div className="relative">
        <input
          type="text"
          placeholder="Search participants by name or NDIS number..."
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute right-3 top-3 h-5 w-5 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 4H16M8 20H16M4 12H20"
          />
        </svg>
      </div>

      {/* Members List */}
      <div className="max-h-60 overflow-y-auto border border-gray-200 rounded-lg shadow-sm">
        {filteredMembers?.map((member) => (
          <div
            key={member._id}
            onClick={() => handleParticipantSelect(member)}
            className="p-3 hover:bg-blue-50 cursor-pointer flex justify-between items-center border-b last:border-b-0"
          >
            <div>
              <span className="font-medium text-gray-800">{member.name}</span>
              <span className="text-sm text-gray-500 ml-2">
                ({member.email})
              </span>
            </div>
            <button
              className="text-blue-500 hover:text-blue-600"
              onClick={(e) => {
                e.stopPropagation();
                handleParticipantSelect(member);
              }}
            >
              Add
            </button>
          </div>
        ))}
        {!filteredMembers?.length && (
          <p className="text-gray-500 text-center py-4">No users found.</p>
        )}
      </div>

      {/* Selected Participants */}
      <div className="max-h-[20vh] overflow-y-auto">
        <h3 className="text-lg font-semibold mb-3">Selected Users</h3>
        <div className="flex flex-wrap gap-3">
          {selectedUsersList.map((user) => (
            <div
              key={user._id}
              className="flex items-center bg-blue-100 text-blue-700 px-2 py-1 text-sm rounded-lg shadow-sm"
            >
              <span className="mr-1">{user.name}</span>
              <button
                onClick={() => handleRemoveParticipant(user._id)}
                className="text-red-500 hover:text-red-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          ))}
          {!selectedUsersList.length && (
            <p className="text-gray-500">No Users selected.</p>
          )}
        </div>
      </div>
      <div className="text-right space-x-3 mt-4">
        <button
          onClick={onClose}
          className="px-4 py-2 rounded-lg shadow-md text-white bg-green-600 hover:bg-green-700 transition duration-200"
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default memo(SelectUserModalContent);
