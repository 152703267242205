import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Bounce, ToastContainer } from 'react-toastify';
import Accounts from "./Pages/accounts/Accounts";
import DashBoard from "./Pages/dashboard/DashBoard";
import MemberPage from "./Pages/members/MemberPage";
import MembersList from "./Pages/members/MembersList";
import NewMember from "./Pages/members/NewMember";
import Settings from "./Pages/settings/Settings";
import Login from "./auth/Login";
import Construction from "./components/Construction/Construction";

import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import DirectSupportDashboard from "./Pages/directSupports/directSupportDashboard/DirectSupportDashboard";
import DirectSupportSettings from "./Pages/directSupports/directSupportSettings/DirectSupportSettings";
import AddInsight from "./Pages/directSupports/insight/AddInsight";
import Insight from "./Pages/directSupports/insight/Insight";
import InsightDetailsPage from "./Pages/directSupports/insight/InsightDetailsPage";
import AddEvent from "./Pages/events/AddEvent";
import EventDetails from "./Pages/events/EventDetails";
import Events from "./Pages/events/Events";
import AddProject from "./Pages/projects/addProject";
import AddStep from "./Pages/projects/addStep";
import ProjectDetails from "./Pages/projects/projectDetails";
import Projects from "./Pages/projects/projects";
import ResetPassword from "./auth/ResetPassword";
import EditProjectForm from "./components/forms/editProjectForm/editProjectForm";
import { useAuth } from "./context/AuthContext";
import useFetchMembers from "./hooks/useFetchMembers";
import DefaultLayout from "./layout/DefaultLayout";
import Loading from "./widgets/loading/Loading";


const AnimatedPage = ({ children }) => {

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  );
};

function App() {
  const location = useLocation();


  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 1000);

    return () => clearTimeout(timer); // This will clear the timeout if the component is unmounted before the timeout finishes
  }, []);

  const { isAuthenticated, userData } = useAuth();

  const { data: members = [], isLoading } = useFetchMembers();

  const renderProtectedRoute = (Component, props = {}) => {
    return isAuthenticated ?
      <DefaultLayout>
        <AnimatedPage>
          <Component {...props} />
        </AnimatedPage>
      </DefaultLayout >
      : <Login />;
  };

  const renderAdminRoutes = () => {
    if (!isLoaded || !userData || userData.position === "tl") return null;
    return (
      <>
        <Route
          path="/accounts"
          element={
            renderProtectedRoute(Accounts)
          }
        />
        <Route
          path="/settings"
          element={
            renderProtectedRoute(Settings)
          }
        />
      </>
    );
  };


  return (
    <div className="App">
      {isLoading ? (
        <Loading />
      ) : (

        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route
              path="/login"
              element={
                !isAuthenticated ? <Login /> : <Navigate to="/" />
              }
            />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/"
              element={
                renderProtectedRoute(DashBoard)
              }
            />


            {/* Direct Support Routes */}
            <Route
              path="/direct-support/dashboard"
              element={renderProtectedRoute(DirectSupportDashboard)}
            />
            <Route
              path="/direct-support/insight"
              element={renderProtectedRoute(Insight)}
            />
            <Route
              path="/direct-support/insight/:id"
              element={renderProtectedRoute(InsightDetailsPage)}
            />
            <Route
              path="/direct-support/insight/add"
              element={renderProtectedRoute(AddInsight)}
            />
            <Route
              path="/direct-support/participants"
              element={renderProtectedRoute(MembersList, { members, loading: isLoading })}
            />
            <Route
              path="/direct-support/settings"
              element={renderProtectedRoute(DirectSupportSettings)}
            />
            <Route
              path="/direct-support/participant/add"
              element={renderProtectedRoute(NewMember, { members })}
            />
            <Route
              path="/direct-support/participant/:id"
              element={renderProtectedRoute(MemberPage, { members, loading: isLoading })}
            />

            {/* Events Routes */}
            <Route
              path="/events"
              element={renderProtectedRoute(Events)}
            />
            <Route
              path="/event/add"
              element={renderProtectedRoute(AddEvent, { members })}
            />
            <Route
              path="/event/:id"
              element={renderProtectedRoute(EventDetails, { members })}
            />


            {/* Projects Routes */}
            <Route
              path="/projects"
              element={renderProtectedRoute(Projects)}
            />
            <Route
              path="/project/:id"
              element={renderProtectedRoute(ProjectDetails)}
            />
            <Route
              path="/project/add"
              element={renderProtectedRoute(AddProject)}
            />
            <Route
              path="/project/addStep/:id"
              element={renderProtectedRoute(AddStep)}
            />
            <Route
              path="/project/edit/:id"
              element={renderProtectedRoute(EditProjectForm)}
            />



            {renderAdminRoutes()}
            <Route
              path="*"
              element={
                <AnimatedPage>
                  <Construction />
                </AnimatedPage>
              }
            />
          </Routes>
        </AnimatePresence>

      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </div>
  );
}

export default App;
