import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api/api';
import AddEventItemForm from '../forms/addEventItemForm/AddEventItemForm';
import EditEventItemForm from '../forms/editEventItemForm/editEventItemForm';
import Modal from '../modal/Modal';
import ModalWithContent from '../modal/ModalWithContent';
import ReusableTable from '../table/ReusableTable';

const EventItems = ({ eventDetails }) => {
  const queryClient = useQueryClient();
  const [isAddEventItemModalOpen, setIsAddEventItemModalOpen] = useState(false);
  const [deleteItemModalOpen, setDeleteItemModalOpen] = useState(false);
  const [editItemModalOpen, setEditItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemId, setItemId] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const { id } = useParams();

  const deleteItem = async () => {
    if (isDeleting) return;
    try {
      setIsDeleting(true);
      const response = await api.put(`/events/${id}`, {
        type: 'deleteEventItem',
        itemId: itemId,
      });

      if (response.status === 200) {
        toast.success('Event Item deleted successfully!');
        queryClient.invalidateQueries(['events', id]);
      } else {
        throw new Error('Failed to delete item');
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error('Failed to delete item. Please try again.');
    } finally {
      setIsDeleting(false);
      setDeleteItemModalOpen(false);
      setItemId('');
    }
  };

  const columnHelper = createColumnHelper();
  // Memoize columns definition
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('itemType', {
        header: 'Item Type',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('description', {
        header: 'Description',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('cost', {
        header: 'Cost',
        cell: (info) => '$' + info.getValue(),
      }),
      columnHelper.accessor('_id', {
        cell: (info) => {
          const currentItems = eventDetails?.items?.find(
            (task) => task._id === info.getValue()
          );
          return (
            <div className="flex gap-2 items-center">
              <MdDelete
                onClick={() => {
                  if (!eventDetails?.status) return;
                  setItemId(info.getValue());
                  setDeleteItemModalOpen(true);
                }}
                className={`${
                  !eventDetails?.status
                    ? 'text-gray-400 cursor-not-allowed'
                    : 'text-red-400 hover:text-red-500 cursor-pointer hover:bg-slate-200 rounded-md'
                } text-3xl p-0.5`}
              />
              <MdEdit
                className={`${
                  !eventDetails?.status
                    ? 'text-gray-400 cursor-not-allowed'
                    : 'text-blue-400 hover:text-blue-500 cursor-pointer hover:bg-slate-200 rounded-md'
                } text-3xl p-0.5`}
                onClick={() => {
                  setSelectedItem(currentItems);
                  setEditItemModalOpen(true);
                }}
              />
            </div>
          );
        },
        header: 'Actions',
      }),
    ],
    [columnHelper]
  );
  return (
    <div className="rounded-lg shadow-lg p-4 sm:p-8 space-y-8 mt-6 bg-white border border-gray-200">
      <div className="mb-5 flex justify-between items-center flex-wrap gap-2">
        <h3 className="text-2xl font-extrabold text-gray-700">Items List</h3>
        <button
          disabled={!eventDetails?.status}
          onClick={() => setIsAddEventItemModalOpen(true)}
          className={`${
            !eventDetails?.status
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-500 hover:bg-blue-600'
          } text-white px-4 py-1 rounded-md`}
        >
          Add Item
        </button>
      </div>
      <div>
        {eventDetails?.items?.length > 0 ? (
          <ReusableTable tableData={eventDetails.items} columns={columns} />
        ) : (
          <p className="text-gray-600 text-center  bg-gray-200 px-10 py-2 rounded-lg">
            No items found
          </p>
        )}
      </div>
      <ModalWithContent
        isOpen={isAddEventItemModalOpen}
        setIsOpen={setIsAddEventItemModalOpen}
        content={
          <AddEventItemForm
            setIsAddEventItemModalOpen={setIsAddEventItemModalOpen}
          />
        }
      />
      <ModalWithContent
        isOpen={editItemModalOpen}
        setIsOpen={setEditItemModalOpen}
        content={
          <EditEventItemForm
            setEditItemModalOpen={setEditItemModalOpen}
            selectedItem={selectedItem}
          />
        }
      />
      <Modal
        btnName={isDeleting ? 'Deleting...' : 'Delete Item'}
        title="Delete Item?"
        message="Are you sure you want to delete this item? This action cannot be undone."
        isOpen={deleteItemModalOpen}
        setIsOpen={setDeleteItemModalOpen}
        exitBtnAction={() => setDeleteItemModalOpen(false)}
        btnAction={() => deleteItem()}
      />
    </div>
  );
};

export default React.memo(EventItems);
