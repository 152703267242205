import React from 'react';

const InputFields = ({ community, handleChangeInput, index, services }) => {
  return (
    <>
      <div className="overflow-x-auto rounded-lg border border-gray-200">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead>
            <tr className="">
              <th className="w-[33%] whitespace-nowrap px-4 py-2 font-bold text-gray-900 border-r text-left ">
                Service Type
              </th>
              <th className="w-[33%] whitespace-nowrap px-4 py-2 font-bold text-gray-900 border-r text-left">
                Hours
              </th>
              <th className="w-[33%] whitespace-nowrap px-4 py-2 font-bold text-gray-900 text-left">
                Sales
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {services.map((service) => (
              <tr>
                <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 border-r">
                  {service.name}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700 border-r">
                  <input
                    name={service.field}
                    type="number"
                    id="UserEmail"
                    placeholder="Input Hours"
                    value={community[service.field].hours}
                    onChange={(e) => handleChangeInput(index, e)}
                    class="mt-1 w-full border-b border-gray-200 rounded-md border-0 shadow-sm sm:text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700 font-bold">
                  ${Number(community[service.field].sales).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InputFields;
