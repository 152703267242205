import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { LiaGreaterThanSolid, LiaLessThanSolid } from 'react-icons/lia';

const ReusableTable = ({ tableData = [], columns }) => {
  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  return (
    <div className="overflow-x-auto">
      <table className="border rounded-lg w-full text-left text-slate-600 min-w-[650px]">
        <thead className="bg-slate-200">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="capitalize px-3.5 py-2">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, i) => (
            <tr
              key={row.id}
              className={i % 2 === 0 ? 'bg-white' : 'bg-slate-50'}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="px-1 lg:px-3.5 py-1 md:py-3">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-start sm:items-center justify-end mt-2 gap-2 text-sm sm:text-base">
        <div className="flex items-center gap-2">
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="p-1 text-indigo-700 border border-gray-100 px-2 rounded-md"
          >
            <LiaLessThanSolid size={20} />
          </button>
          <span className="flex text-slate-600 items-center gap-1">
            <p>Page</p>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="p-1 text-indigo-700 border border-gray-100 px-2 rounded-md"
          >
            <LiaGreaterThanSolid size={20} />
          </button>
        </div>

        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            className="border p-1 rounded w-24 sm:w-32 h-8 border-slate-100 text-slate-600"
          >
            {[10, 20, 30, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ReusableTable);
