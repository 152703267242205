import Avvvatars from 'avvvatars-react';
import React from 'react';
import { CiCalendar } from 'react-icons/ci';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { SiAwsorganizations } from 'react-icons/si';
import { useAuth } from '../../../context/AuthContext';
import { getPositionDepartment } from '../../../utils/positionDepartment';

const Header = () => {
  const { userData } = useAuth();
  const positionDepartment = getPositionDepartment(
    userData.position,
    userData.department
  );
  return (
    <header>
      <div>
        {/* Code block starts */}
        <div className="container lg:px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between  border-b border-gray-300 pb-2">
          <div className="mb-2 lg:mb-0">
            <h4 className="text-2xl font-bold leading-tight text-slate-800 ">
              {userData.name}
            </h4>
            <ul className="flex flex-col lg:flex-row items-start lg:items-center text-gray-600 dark:text-gray-400 text-sm mt-3">
              <li className="flex items-center mr-4">
                <SiAwsorganizations className="mr-1" />

                <span>{positionDepartment.position}</span>
              </li>
              <li className="flex items-center mr-4 mt-4 lg:mt-0">
                <MdOutlineMarkEmailRead className="mr-1" />
                <span>{userData.email}</span>
              </li>
              <li className="flex items-center mt-4 lg:mt-0">
                <CiCalendar className="mr-2" />
                <span>{positionDepartment.department}</span>
              </li>
            </ul>
          </div>

          <Avvvatars value={userData.email} size={50} style="shape" />
        </div>
      </div>
    </header>
  );
};

export default Header;
