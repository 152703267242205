import { FaProjectDiagram } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import BreadCrumb from '../../components/breadCrumb/BreadCrumb';
import ProjectDetailsComponent from '../../components/ProjectDetails/ProjectDetailsComponent';
import ProjectSteps from '../../components/ProjectDetails/ProjectSteps';
import useGetProjectDetails from '../../hooks/useGetProjectDetails';
import getUserData from '../../utils/getUserData';
import Loading from '../../widgets/loading/Loading';

const ProjectDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: projectDetails, isLoading } = useGetProjectDetails(id);

  const user = getUserData();
  const isUserStakeholder = () => {
    return projectDetails?.stakeHolders.some(
      (stakeholder) => stakeholder.userId === user._id
    );
  };

  if (isLoading) return <Loading />;

  return (
    <div>
      <BreadCrumb
        currentPage="Project Details"
        prevPage="Projects"
        icon={<FaProjectDiagram />}
        navigation={() => navigate('/projects')}
      />
      <ProjectDetailsComponent projectDetails={projectDetails} />
      {(isUserStakeholder() || user.role === 'admin') && (
        <ProjectSteps projectDetails={projectDetails} />
      )}
    </div>
  );
};

export default ProjectDetails;
