import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../api/api';
import useFetchDSSettings from '../../../hooks/useFetchDSSettings';
import useGetAllUser from '../../../hooks/useGetUser';
import Input from '../formElements/inputs/Input';
import LoadingButton from '../formElements/loadingButton/LoadingButton';
import SelectInput from '../formElements/slectInput/SelectInput';
import TextArea from '../formElements/textArea/TextArea';
import SubmitButton from './../formElements/submitButton/SubmitButton';

const AddInsightForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  // Form state
  const [formData, setFormData] = useState({
    dateIdentified: '',
    category: '',
    linkedTo: '',
    name: '',
    description: '',
    impactLevel: '',
    assignedTo: [],
    dueDate: '',
    createdBy: '',
  });

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user_data'));
    setFormData((prev) => ({
      ...prev,
      createdBy: userData?.user?.name || '',
    }));
  }, []);

  const { data: settings, isLoading: loadingSettings } = useFetchDSSettings();
  const { data: users, isLoading } = useGetAllUser();

  const transformedCategories =
    settings?.[0]?.categories?.map((category) => ({
      value: category.categoryName.toLowerCase(),
      name: category.categoryName,
    })) || [];

  const impactOptions = [
    { value: 'low', name: 'Low' },
    { value: 'medium', name: 'Medium' },
    { value: 'high', name: 'High' },
  ];

  const linkedToItems = ['Staff', 'Participant', 'Internal', 'Others'];

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if assignedTo is empty
    if (formData.assignedTo.length === 0) {
      toast.error('Please assign at least one user');
      return;
    }

    setLoading(true);

    try {
      await api.post('/insights', formData);
      await queryClient.invalidateQueries({ queryKey: ['insights'] });
      toast.success('Insight added successfully');
      navigate('/direct-support/insight');
    } catch (error) {
      console.error('Error submitting insight:', error);
      toast.error('Error submitting insight');
    } finally {
      setLoading(false);
    }
  };

  const handleAssignUser = (userId) => {
    const selectedUser = users.find((user) => user._id === userId);
    if (
      selectedUser &&
      !formData.assignedTo.some((user) => user._id === userId)
    ) {
      setFormData((prev) => ({
        ...prev,
        assignedTo: [...prev.assignedTo, selectedUser],
      }));
    }
  };

  const removeAssignedUser = (userId) => {
    setFormData((prev) => ({
      ...prev,
      assignedTo: prev.assignedTo.filter((user) => user._id !== userId),
    }));
  };

  return (
    <div className="w-full shadow-md rounded-md mt-2">
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="w-full h-14 bg-slate-200">
          <h3 className="text-xl p-4 font-bold text-slate-600">Insight</h3>
        </div>
        <div className="md:py-4 pl-5 md:pl-10 xl:pl-14">
          <Input
            value={formData.dateIdentified}
            change={(value) =>
              setFormData((prev) => ({ ...prev, dateIdentified: value }))
            }
            type="date"
            id="dateIdentified"
            placeholder="Date Identified"
          />

          <SelectInput
            options={transformedCategories}
            id="category"
            placeholder="Category"
            value={formData.category}
            change={(value) =>
              setFormData((prev) => ({ ...prev, category: value }))
            }
          />

          <div className="my-4">
            <label className="block font-medium text-gray-700 mb-2 ">
              Linked To
            </label>
            <div className="flex flex-wrap items-center gap-6">
              {linkedToItems.map((item) => (
                <div key={item} className="flex items-center">
                  <input
                    type="radio"
                    id={item}
                    name="linkedTo"
                    value={item}
                    checked={formData.linkedTo === item}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        linkedTo: e.target.value,
                      }))
                    }
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500"
                  />
                  <label htmlFor={item} className="ml-2 text-sm text-gray-700">
                    {item}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <Input
            value={formData.name}
            change={(value) =>
              setFormData((prev) => ({ ...prev, name: value }))
            }
            type="text"
            id="name"
            placeholder="Name"
            disabled={!formData.linkedTo}
          />

          <TextArea
            value={formData.description}
            change={(value) =>
              setFormData((prev) => ({ ...prev, description: value }))
            }
            id="description"
            placeholder="Issue Description"
          />

          <SelectInput
            options={impactOptions}
            id="impactLevel"
            placeholder="Impact Level"
            value={formData.impactLevel}
            change={(value) =>
              setFormData((prev) => ({ ...prev, impactLevel: value }))
            }
          />

          <div className="mb-4">
            <SelectInput
              options={
                users?.map((user) => ({
                  value: user._id,
                  name: user.name,
                })) || []
              }
              id="assignedUsers"
              placeholder="Assigned To"
              value=""
              change={handleAssignUser}
              required={false}
            />

            <div className="mt-2 flex flex-wrap gap-2">
              {formData.assignedTo.map((user) => (
                <div
                  key={user._id}
                  className="flex items-center bg-blue-100 px-3 py-1 rounded-full"
                >
                  <span>{user.name}</span>
                  <button
                    type="button"
                    onClick={() => removeAssignedUser(user._id)}
                    className="ml-2 text-gray-600 hover:text-red-600"
                  >
                    <IoMdClose />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <Input
            value={formData.dueDate}
            change={(value) =>
              setFormData((prev) => ({ ...prev, dueDate: value }))
            }
            type="date"
            id="dueDate"
            placeholder="Due Date"
          />

          <Input
            value={formData.createdBy}
            type="text"
            id="createdBy"
            placeholder="Created By"
            disabled={true}
          />

          <div>
            {loading ? (
              <LoadingButton />
            ) : (
              <SubmitButton
                btnName="Add Insight"
                icon={<AiOutlineArrowRight />}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default React.memo(AddInsightForm);
