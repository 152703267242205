import Tooltip from '@mui/material/Tooltip';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import { MdFileDownloadDone, MdUpdate } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../../utils/DateFormation';
import getUserData from '../../utils/getUserData';
import MarkProjectCompleteForm from '../forms/markProjectCompleteForm/MarkProjectCompleteForm';
import UpdateProjectStepDeadlineForm from '../forms/updateProjectStepDeadlineForm/UpdateProjectStepDeadlineForm';
import ModalWithContent from '../modal/ModalWithContent';
import ReusableTable from '../table/ReusableTable';

const ProjectSteps = ({ projectDetails }) => {
  const { steps } = projectDetails;

  const navigate = useNavigate();
  const { id } = useParams();

  const [openMarkAsCompleteModal, setOpenMarkAsCompleteModal] = useState(false);
  const [openUpdateDeadlineModal, setOpenUpdateDeadlineModal] = useState(false);
  const [stepId, setStepId] = useState('');

  const user = getUserData();

  const columnHelper = createColumnHelper();

  const columns = React.useMemo(() => {
    return [
      columnHelper.accessor('description', {
        header: 'Description',
      }),
      columnHelper.accessor('startDate', {
        header: 'Start Date',
        cell: (info) => (
          <span className="text-nowrap">{formatDate(info.getValue())}</span>
        ),
      }),
      columnHelper.accessor('timeInvested', {
        header: 'Time Invested',
        cell: (info) => (
          <span className="text-nowrap">
            {info.getValue() ? info.getValue() + ' hours' : 'Not Completed'}
          </span>
        ),
      }),
      columnHelper.accessor('peopleInvolved', {
        header: 'People Involved',
        cell: (info) => (
          <div className="flex flex-wrap gap-1">
            {info.getValue().map((stakeholder) => (
              <span
                key={stakeholder._id}
                className="px-2 py-1 rounded-lg text-sm bg-gray-200 text-gray-800"
              >
                {stakeholder.name}
              </span>
            ))}
          </div>
        ),
      }),
      columnHelper.accessor('deadline', {
        header: 'Deadlines',
        cell: (info) => {
          const deadlines = info.getValue();
          if (deadlines.length === 0) return 'No deadline';

          // Parse the dates
          const firstDate = new Date(deadlines[0]);
          const lastDate = new Date(deadlines[deadlines.length - 1]);

          // Calculate the difference in days
          const dayDifference = Math.round(
            (lastDate - firstDate) / (1000 * 60 * 60 * 24)
          );

          return (
            <div>
              {deadlines.length > 1 ? (
                <div>
                  <div>{formatDate(lastDate)}</div>Deadline pushed by{' '}
                  {dayDifference} days
                </div>
              ) : (
                <div>{formatDate(lastDate)}</div>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('', {
        header: 'Actions',
        cell: (info) => {
          const isInvolved = () => {
            return info.row.original?.peopleInvolved.some(
              (people) => people.userId === user._id
            );
          };

          const isDisabled =
            !(isInvolved() || user?.role === 'admin') ||
            info.row.original?.completeStatus;

          return (
            <div className="flex items-center gap-2 text-2xl group relative">
              <Tooltip title="Push Deadline" arrow>
                <button
                  onClick={() => {
                    setOpenUpdateDeadlineModal(true);
                    setStepId(info.row.original._id);
                  }}
                  type="button"
                  disabled={isDisabled}
                >
                  <MdUpdate
                    className={!isDisabled ? 'text-red-600' : 'text-gray-400'}
                  />
                </button>
              </Tooltip>

              <Tooltip title="Mark Complete" arrow>
                <button
                  type="button"
                  disabled={isDisabled}
                  onClick={() => {
                    setOpenMarkAsCompleteModal(true);
                    setStepId(info.row.original._id);
                  }}
                >
                  <MdFileDownloadDone
                    className={!isDisabled ? 'text-green-600' : 'text-gray-400'}
                  />
                </button>
              </Tooltip>

              {isDisabled && (
                <div className="absolute bottom-full -right-10 transform -translate-x-1/2 mb-2 hidden group-hover:block">
                  <div className="bg-gray-800/90 text-white rounded py-1 px-3 text-sm whitespace-nowrap">
                    {info.row.original?.completeStatus
                      ? 'Project is already completed'
                      : 'Only Admin or people involved can modify'}
                  </div>
                </div>
              )}
            </div>
          );
        },
      }),
    ];
  }, [columnHelper]);

  return (
    <div className="mt-4 p-3 sm:p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        Steps of the projects and deadline
      </h2>
      <div>
        <div className="flex justify-end">
          <button
            onClick={() => navigate(`/project/addStep/${id}`)}
            className="bg-blue-500 text-white px-4 py-1 rounded-md"
          >
            Add Step
          </button>
        </div>
      </div>

      <div className="mt-4">
        {steps.length > 0 ? (
          <ReusableTable tableData={steps || []} columns={columns} />
        ) : (
          <h1 className="uppercase tracking-widest text-gray-500 mt-16 text-center">
            No steps available
          </h1>
        )}
      </div>
      <ModalWithContent
        content={
          <UpdateProjectStepDeadlineForm
            stepId={stepId}
            setStepId={setStepId}
            setOpenUpdateDeadlineModal={setOpenUpdateDeadlineModal}
          />
        }
        isOpen={openUpdateDeadlineModal}
        setIsOpen={setOpenUpdateDeadlineModal}
      />
      <ModalWithContent
        isOpen={openMarkAsCompleteModal}
        setIsOpen={setOpenMarkAsCompleteModal}
        content={
          <MarkProjectCompleteForm
            setOpenMarkAsCompleteModal={setOpenMarkAsCompleteModal}
            stepId={stepId}
            setStepId={setStepId}
          />
        }
      />
    </div>
  );
};

export default ProjectSteps;
