import React from 'react';

const NumberStat = ({
  value,
  label,
  icon,
  iconBackground,
  textColor,
  cardBackground,
  textSize = 'text-5xl',
}) => {
  return (
    <div
      className={`p-4 lg:p-7 rounded-lg space-y-3 shadow-xl relative ${cardBackground}`}
    >
      <div className={`${textSize} font-bold text-gray-500`}>{value}</div>
      <div className={`${textColor} font-semibold`}>{label}</div>
      <div
        className={`w-11 h-11 absolute top-3 right-3 rounded-full flex items-center justify-center z-10 ${iconBackground} ${textColor}`}
      >
        {icon}
      </div>
    </div>
  );
};

export default React.memo(NumberStat);
