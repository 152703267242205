import { useMemo } from 'react';

const HOURLY_RATE = 67.56;

export const useEventRevenue = (data) => {
    return useMemo(() => {
        const defaultValues = {
            generatedRevenue: null,
            totalProjectedRevenue: null,
            belowTarget: null,
            belowTargetPercentage: null
        };

        if (!data) return defaultValues;

        const currentDate = new Date();
        const startDate = new Date(data?.startDate);
        const isDateDisabled = startDate >= currentDate;

        if (isDateDisabled) return defaultValues;

        const totalHours = Object.values(data.participantAttendance || {})
            .reduce((sum, participant) => sum + (participant.attendedHours || 0), 0);

        const generatedRevenue = totalHours * HOURLY_RATE;
        const totalProjectedRevenue = (data.duration || 0) * (data.participantsIds?.length || 0) * HOURLY_RATE;

        const belowTarget = generatedRevenue - totalProjectedRevenue;
        const belowTargetPercentage = totalProjectedRevenue !== 0
            ? ((belowTarget / totalProjectedRevenue) * 100).toFixed(2)
            : 0;

        return {
            generatedRevenue,
            totalProjectedRevenue,
            belowTarget,
            belowTargetPercentage
        };
    }, [data]);
};

export default useEventRevenue;