import AddStepForm from '../../components/forms/AddStepForm/AddStepForm';

const AddStep = () => {
  return (
    <div>
      <AddStepForm />
    </div>
  );
};

export default AddStep;
