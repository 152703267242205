import { useQuery } from '@tanstack/react-query';
import api from '../api/api';

const useFetchMembers = () => {
    return useQuery({
        queryKey: ['membersWithDetails'],
        queryFn: async () => {
            const response = await api.get("/membersWithDetails");
            return response.data;
        },
    });
};

export default useFetchMembers;
