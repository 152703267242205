import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { MdInsights } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../api/api';
import BreadCrumb from '../../../components/breadCrumb/BreadCrumb';
import InsightDetails from '../../../components/insightDetails/InsightDetails';
import Loading from '../../../widgets/loading/Loading';

const InsightDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const navigation = () => {
    navigate('/direct-support/insight');
  };
  const {
    data: insightDetails = {},
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ['insights', id],
    queryFn: async () => {
      const response = await api.get(`/insights/${id}`);
      return response.data;
    },
  });

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <BreadCrumb
            currentPage="Insight Details"
            prevPage="Insight"
            icon={<MdInsights />}
            navigation={navigation}
          />
          <InsightDetails insightDetails={insightDetails} />
        </div>
      )}
    </div>
  );
};

export default React.memo(InsightDetailsPage);
