import AddProjectForm from '../../components/forms/addProjectForm/addProjectForm';

const AddProject = () => {
  return (
    <div>
      <AddProjectForm />
    </div>
  );
};

export default AddProject;
