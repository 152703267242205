import { useEffect, useState } from 'react';
import { calculateTotalFunding } from './../utils/calculateTotalFunding';
import { calculateSpending } from './../utils/fundingStatus';
import useFetchMembers from './useFetchMembers';

const useGetMemberWithBudget = () => {
    const { data: members = [], isLoading } = useFetchMembers();
    const [memberWithCalculatedBudget, setMemberWithCalculatedBudget] = useState(
        []
    );
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const calculateBudgets = async (members) => {
        try {
            const memberWithCalculatedBudget = await Promise.all(
                members.map(async (member) => {
                    try {
                        let budgetStatus = 'No Data';

                        // Check if member and required properties exist
                        if (!member.details?.clientInfo) {
                            return { ...member, budgetStatus };
                        }

                        // Check for inactive status first
                        if (member.details.clientInfo.status === 'Inactive') {
                            budgetStatus = 'Inactive';
                        } else if (
                            member.details.status === 'Active' &&
                            member.details.planBudgets
                        ) {
                            const coreFundsTotal = calculateTotalFunding(
                                member.details.planBudgets,
                                'core budget'
                            );

                            const spending = calculateSpending(
                                member.details.ndisPlanStartDate,
                                member.details.ndisPlanEndDate,
                                coreFundsTotal.totalBudget,
                                coreFundsTotal.totalRemaining
                            );

                            // Determine budget status based on spending
                            if (spending.overspending > 0) {
                                budgetStatus = 'Overspending';
                            } else if (spending.underspending > 0) {
                                budgetStatus = 'Underspending';
                            } else {
                                budgetStatus = 'On Track';
                            }
                        }

                        return { ...member, budgetStatus };
                    } catch (error) {
                        console.error(
                            `Failed to process member ${member._id}: ${error.message}`
                        );
                        return { ...member, budgetStatus: 'Error' };
                    }
                })
            );

            return memberWithCalculatedBudget;
        } catch (error) {
            console.error('Error processing members:', error.message);
            throw error; // Let the component handle the error
        }
    };

    useEffect(() => {
        const loadMembersWithDetails = async () => {
            try {
                const data = await calculateBudgets(members);
                setMemberWithCalculatedBudget(data);
                setLoading(false);
            } catch (error) {
                setIsError(true);
                setLoading(false);
            }
        };

        loadMembersWithDetails();
    }, [members]);

    return {
        memberWithCalculatedBudget,
        loading: loading || isLoading,
        isError
    };
};

export default useGetMemberWithBudget;
